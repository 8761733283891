import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ClickOutsideModule } from 'ng-click-outside';

// Services and Common
import { ChangelogService } from './services/changelog.service';
import { ColorTileComponent } from './common/color-tile/color-tile.component';
import { ClipboardComponent } from './common/clipboard/clipboard.component';
import { ExternalResourceComponent } from './common/external-resource/external-resource.component';
import { FeedbackComponent } from './common/feedback/feedback.component';
import { FormatCrumbsPipe } from './common/functions/format-crumbs';
import { ObserveVisibilityDirective } from './common/observe-visibility/observe-visibility.directive';
import { PageSubNavComponent } from './common/page-sub-nav/page-sub-nav.component';
import { SearchComponent } from './common/search/search.component';
import { StyleRowComponent } from './common/style-row/style-row.component';
import { ThemeSwitcherComponent } from './common/theme-switcher/theme-switcher.component';
import { WindowScrollService } from './services/window-scroll.service';

// General
import { GeneralComponent } from './general/general.component';
import { ChangelogComponent } from './general/changelog/changelog.component';
import { ContactComponent } from './general/contact/contact.component';
import { ContributeComponent } from './general/contribute/contribute.component';
import { FaqComponent } from './general/faq/faq.component';
import { GlossaryComponent } from './general/glossary/glossary.component';

// Guidelines
import { AccessibilityComponent } from './guidelines/accessibility/accessibility.component';
import { BrandingComponent } from './guidelines/branding/branding.component';
import { ColorsComponent } from './guidelines/colors/colors.component';
import { ContentComponent } from './guidelines/content/content.component';
import { DataVisualizationComponent } from './guidelines/data-visualization/data-visualization.component';
import { FormattingComponent } from './guidelines/formatting/formatting.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { IconsComponent } from './guidelines/icons/icons.component';
import { IconsDetailComponent } from './guidelines/icons/icons-detail/icons-detail.component';
import { MotionComponent } from './guidelines/motion/motion.component';
import { SeverityGuidelineComponent } from './guidelines/severity/severity.component';
import { SpacingComponent } from './guidelines/spacing/spacing.component';
import { TypographyComponent } from './guidelines/typography/typography.component';

// Components
import { AccordionComponent } from './components/accordion/accordion.component';
import { BannerComponent } from './components/banner/banner.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { ComponentsComponent } from './components/components.component';
import { ContainerModalExampleComponent } from './components/modal/container-example/container-modal-example.component';
import { ComplexFullscreenRightModalExampleComponent } from './components/modal/complex-fullscreen-right/complex-fullscreen-right.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HelpTextComponent } from './components/help-text/help-text.component';
import { IconComponent } from './components/icon/icon.component';
import { LabelValueComponent } from './components/label-value/label-value.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { ListComponent } from './components/list/list.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NumberFieldComponent } from './components/number-field/number-field.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { PopupComponent } from './components/popup/popup.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { SliderComponent } from './components/slider/slider.component';
import { SeverityComponent } from './components/severity/severity.component';
import { ToastComponent } from './components/toast/toast.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TabComponent } from './components/tab/tab.component';
import { TagComponent } from './components/tag/tag.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextLinkComponent } from './components/text-link/text-link.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';

// Patterns
import { ApplicationHeaderComponent } from './patterns/application-header/application-header.component';
import { ApplicationMenuComponent } from './patterns/application-menu/application-menu.component';
import { CardComponent } from './patterns/card/card.component';
import { DashboardComponent } from './patterns/dashboard/dashboard.component';
import { ErrorPageComponent } from './patterns/error-page/error-page.component';
import { EmptyStateComponent } from './patterns/empty-state/empty-state.component';
import { FilterComponent } from './patterns/filter/filter.component';
import { FormComponent } from './patterns/form/form.component';
import { LoginComponent } from './patterns/login/login.component';
import { NotificationComponent } from './patterns/notification/notification.component';
import { PanelComponent } from './patterns/panel/panel.component';
import { PatternsComponent } from './patterns/patterns.component';
import { TreeBrowserComponent } from './patterns/tree-browser/tree-browser.component';
import { WizardComponent } from './patterns/wizard/wizard.component';

// Kendo
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IconsModule, ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { MenuModule } from '@progress/kendo-angular-menu';
import 'hammerjs';

import { PrismComponent } from './common/prism/prism.component';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-scss';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ToastTemplateComponent } from './components/toast/toast-template.component';
import { FilterModule } from '@progress/kendo-angular-filter';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { PagerModule } from '@progress/kendo-angular-pager';


//Okta
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

//Environment
import { environment } from 'src/environments/environment';
import { ColorTileOptionComponent } from './common/color-tile-option/color-tile-option.component';
import { BadgeComponent } from './components/badge/badge.component';

const oktaAuth = new OktaAuth({
	issuer: `https://${environment.oktaIssuer}/oauth2/default`,
	clientId: environment.oktaClientId,
	redirectUri: window.location.origin + '/login/callback'
  });

@NgModule({ declarations: [
        AccessibilityComponent,
        AccordionComponent,
        AppComponent,
        ApplicationHeaderComponent,
        ApplicationMenuComponent,
        BannerComponent,
        BarChartComponent,
        BrandingComponent,
        BreadcrumbComponent,
        ButtonComponent,
        ButtonGroupComponent,
        ChangelogComponent,
        CheckboxComponent,
        ClipboardComponent,
        ColorsComponent,
        ColorTileComponent,
        ColorTileOptionComponent,
        ComboBoxComponent,
        ComplexFullscreenRightModalExampleComponent,
        ComponentsComponent,
        ContactComponent,
        ContainerModalExampleComponent,
        ContentComponent,
        ContributeComponent,
        DashboardComponent,
        DataGridComponent,
        DataVisualizationComponent,
        DataTableComponent,
        DatePickerComponent,
        DonutChartComponent,
        DropDownComponent,
        EmptyStateComponent,
        ErrorPageComponent,
        ExternalResourceComponent,
        FaqComponent,
        FeedbackComponent,
        FileUploadComponent,
        FilterComponent,
        FormatCrumbsPipe,
        FormattingComponent,
        FormComponent,
        GeneralComponent,
        GlossaryComponent,
        GuidelinesComponent,
        HelpTextComponent,
        IconComponent,
        IconsComponent,
        IconsDetailComponent,
        LabelValueComponent,
        LineChartComponent,
        ListComponent,
        LoginComponent,
        MenuComponent,
        ModalComponent,
        MotionComponent,
        NavigationComponent,
        NotificationComponent,
        NumberFieldComponent,
        ObserveVisibilityDirective,
        PageSubNavComponent,
        PaginationComponent,
        PanelComponent,
        PasswordFieldComponent,
        PatternsComponent,
        PieChartComponent,
        PopupComponent,
        ProgressIndicatorComponent,
        RadioButtonComponent,
        RangeSliderComponent,
        SearchComponent,
        SeverityComponent,
        SeverityGuidelineComponent,
        SliderComponent,
        ToastComponent,
        ToastTemplateComponent,
        SpacingComponent,
        StyleRowComponent,
        SwitchComponent,
        TabComponent,
        TagComponent,
        TextFieldComponent,
        TextLinkComponent,
        ThemeSwitcherComponent,
        TimelineComponent,
        TimePickerComponent,
        TooltipComponent,
        TreeBrowserComponent,
        TypographyComponent,
        WizardComponent,
        PrismComponent,
        CardComponent,
		BadgeComponent
    ],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ButtonsModule,
        ClickOutsideModule,
        DropDownsModule,
        FormsModule,
        GridModule,
        InputsModule,
        IconsModule,
        LabelModule,
        OktaAuthModule,
        OktaAuthModule.forRoot({ oktaAuth }),
        TooltipsModule,
        ChartsModule,
        ReactiveFormsModule,
        MenuModule,
        LayoutModule,
        NotificationModule,
        FilterModule,
        ProgressBarModule,
        DialogsModule,
        DatePickerModule,
        DateInputsModule,
        NavigationModule,
        PagerModule], providers: [
        WindowScrollService,
        { provide: ICON_SETTINGS, useValue: { type: 'svg' } },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { searchData } from 'src/app/common/search/search-data';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

@Component({
	selector: 'app-glossary',
	templateUrl: './glossary.component.html',
	styleUrls: ['./glossary.component.less'],
})
export class GlossaryComponent implements OnInit {
	public pageData = searchData;
	public currPageData = [];
	public currVisible = 'overview';

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Address Group',
			value: 'address-group',
			empty: false,
			children: [],
		},
		{
			name: 'Dialog vs Dialogue',
			value: 'dialog-dialogue',
			empty: false,
			children: [],
		},
		{
			name: 'Drop-down',
			value: 'drop-down',
			empty: false,
			children: [],
		},
		{
			name: 'Email',
			value: 'email',
			empty: false,
			children: [],
		},
		{
			name: 'Log In vs Login',
			value: 'login',
			empty: false,
			children: [],
		},
		{
			name: 'Log Out vs Logout',
			value: 'logout',
			empty: false,
			children: [],
		},
		{
			name: 'Organization',
			value: 'organization',
			empty: false,
			children: [],
		},
		{
			name: 'Smart Group',
			value: 'smart-group',
			empty: false,
			children: [],
		},
		{
			name: 'Smart Rule',
			value: 'smart-rule',
			empty: false,
			children: [],
		},

		{
			name: 'Username vs User Name',
			value: 'username',
			empty: false,
			children: [],
		},

		{
			name: 'Workgroup',
			value: 'workgroup',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: true,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnInit(): void {
		this.currPageData = this.pageData.filter((x) => x.name === 'Glossary');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	// public changeCurrTheme(event): void {
	// 	this.currTheme = event.target.value;
	// }

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}

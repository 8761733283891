<main class="page-main">
    <app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
    <section class="page-section" id="overview">
        <h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('overview')">
            Overview<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path
                    d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
            </svg>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                Data visualization, or charting, shows information in a graph.
                We use data visualization to display complex and dense data in a
                way that's easy for users to understand. Data visualization
                helps users see patterns, answer questions, and make decisions.
            </li>
        </ul>
    </section>
    <section class="page-section" id="principles">
        <h2 id="sectionTitlePrinciples" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('principles')">
            Principles<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path
                    d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
            </svg>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                Effective data visualization should:
            </li>
            <li class="page-section-list-item">
                <span class="page-section-list-item-sub-title">Adapt to
                    different users</span>
                Different users have different data needs. Data visualization
                should factor in granularity and scalability &#8212; how will a chart
                change with variations in detail, or with more or fewer data
                points?
            </li>
            <li class="page-section-list-item">
                <span class="page-section-list-item-sub-title">Assist in telling
                    a story</span>
                Data visualization should communicate messages clearly. Users
                should be able to explore and interpret data in a way that's
                engaging and intuitive.
            </li>
            <li class="page-section-list-item">
                <span class="page-section-list-item-sub-title">Accurately
                    represent information</span>
                Users should be able to understand the message without
                distortion. Data visualization should keep the integrity of the
                original data.
            </li>
        </ul>
    </section>
    <section class="page-section" id="choosing-the-right-chart">
        <h2 id="sectionTitleChoosingTheRightChart" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('choosing-the-right-chart')">
            Choosing the right chart<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path
                    d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
            </svg>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                When deciding how to visualize data, ask yourself what kind of data you’re presenting and what message
                you’re trying to
                communicate.
            </li>
        </ul>

        <section class="page-sub-section" id="choosing-the-right-chart-data-type">
            <h3 id="subSectionTitleChoosingTheRightChartDataType" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-data-type')">
                What type of data is it?<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
                </svg>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Data that can be split into groups is categorical data. Use
                    a <a href="components/bar-chart">bar</a> or <a href="components/pie-chart">pie chart</a> for
                    categorical data.
                </li>
                <li class="page-section-list-item">
                    Nominal numbers are used to identify someone or something,
                    not to denote an actual value or quantity, such as the
                    number on the back of a footballer's shirt, the number on a
                    racing car, or a house number. Ordinal number defining a
                    thing's position in a series, such as first, second or
                    third. Use <a href="components/pie-chart">bar charts</a> for ordinal data, <a
                        href="components/pie-chart">pie charts</a> for
                    nominal.
                </li>
                <li class="page-section-list-item">
                    Discrete data has a finite number of values. Use bar charts
                    for discrete data, and line charts for continuous data.
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="choosing-the-right-chart-trying-to-say">
            <h3 id="subSectionTitleChoosingTheRightChartTryingToSay" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-trying-to-say')">
                What are you trying to say about it?<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
                </svg>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-list-item-sub-title">Make a
                        comparison</span>
                    If you want to compare values, use a <a href="components/pie-chart">pie chart</a> for relative
                    comparison or a <a href="components/bar-chart">bar chart</a> for precise comparison.
                </li>
                <li class="page-section-list-item">
                    If you want to compare volumes, use an area chart or a
                    bubble chart.
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-list-item-sub-title">Show
                        correlation and changes</span>
                    If you want to demonstrate trends, patterns, or changes over
                    time, use a line chart, <a href="components/bar-chart">bar chart</a>, or scatter plot.
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-list-item-sub-title">Relationships</span>
                    If you want to show a relationship between data, choose
                    scatter plots, bubble charts, or line charts.
                </li>
            </ul>
        </section>

        <section class="page-sub-section" id="choosing-the-right-chart-bar">
            <h3 id="subSectionTitleChoosingTheRightChartBar" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-bar')">
                Bar<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
                </svg>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to use:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            Comparing parts of a bigger set of data,
                            highlighting different categories, or showing change
                            over time.
                        </li>
                        <li class="page-section-list-item">
                            Have categories with long labels—<a href="components/bar-chart">bar charts</a> offer
                            space.
                        </li>
                        <li class="page-section-list-item">
                            If you want to illustrate both positive and negative
                            values in the dataset.
                        </li>
                    </ul>
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to
                        avoid:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            If you're using multiple data points.
                        </li>
                        <li class="page-section-list-item">
                            If you have many categories, avoid overloading your
                            graph. Your graph shouldn't have more than 7 bars.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="choosing-the-right-chart-donut-and-pie">
            <h3 id="subSectionTitleChoosingTheRightChartDonutAndPie" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-donut-and-pie')">
                Donut and pie<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
                </svg>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to use:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            To show relative proportions and percentages of a
                            whole data set.
                        </li>
                        <li class="page-section-list-item">
                            If you have a small dataset.
                        </li>
                        <li class="page-section-list-item">
                            To compare the effect of one factor on different
                            categories.
                        </li>
                        <li class="page-section-list-item">
                            If you have up to 6 categories.
                        </li>
                        <li class="page-section-list-item">
                            If the data is nominal and not ordinal.
                        </li>
                    </ul>
                </li>
                <li class="page-section-list-itngem">
                    <span class="page-section-do-list-title">When to
                        avoid:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            If you have a big dataset.
                        </li>
                        <li class="page-section-list-item">
                            If you want to make a precise or absolute comparison
                            between values.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="choosing-the-right-chart-line">
            <h3 id="subSectionTitleChoosingTheRightChartLine" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-line')">
                Line<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
                </svg>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to use:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            If you have a continuous dataset that changes over
                            time.
                        </li>
                        <li class="page-section-list-item">
                            If your dataset is too big for a bar chart.
                        </li>
                        <li class="page-section-list-item">
                            If you want to display multiple series for the same
                            timeline.
                        </li>
                        <li class="page-section-list-item">
                            If you want to visualize trends instead of exact
                            values.
                        </li>
                    </ul>
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to
                        avoid:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            Line charts work better with bigger datasets, so, if
                            you have a small one, use a <a href="components/bar-chart">bar chart</a> instead.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </section>
    <section class="page-section" id="related">
        <h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('related')">
            Related<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path
                    d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
            </svg>
        </h2>
        <span class="page-related-list-title">Referenced in this section:</span>
        <ul class="page-section-list page-related-list">
            <li class="page-related-list-item">
                <a href="components/bar-chart">Bar chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="components/donut-chart">Donut chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="components/line-chart">Line chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="components/pie-chart">Pie chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="guidelines/typography">Typography guidelines</a>
            </li>
        </ul>
    </section>
    <section class="page-section" id="history">
        <h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('history')">
            Revision history<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path
                    d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
            </svg>
        </h2>
        <ul class="page-history-list">
            <li class="page-history-list-item" *ngFor="
    					let subItem of changelogService.getChangelogBySection(
    						'Data Visualization Guidelines'
    					)
    				">
                <span class="page-history-list-item-title">{{
                    changelogService.formatDate(subItem.changeDate)
                    }}</span>
                <a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
    						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
    						subItem.workId
    					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
            </li>
        </ul>
    </section>
</main>
// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: passwordFieldStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const passwordFieldStylesDefaultTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: '',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	// {
	// 	name: 'Instructional text',
	// 	property: 'color',
	// 	value: '253746',
	// 	isColorTile: true
	// },
	{
		name: 'Error border',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	}
];
export const passwordFieldStylesDefaultErrorTheme = [
	{
		name: 'Progress bar',
		property: 'background color',
		value: 'C1001B',
		isColorTile: true,
	},
];
export const passwordFieldStylesDefaultWarningTheme = [
	{
		name: 'Progress bar',
		property: 'background color',
		value: 'FF9C00',
		isColorTile: true,
	},
];
export const passwordFieldStylesDefaultSuccessTheme = [
	{
		name: 'Progress bar',
		property: 'background color',
		value: '006E18',
		isColorTile: true,
	},
];
export const passwordFieldStylesDarkTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: '',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	// {
	// 	name: 'Instructional text',
	// 	property: 'color',
	// 	value: 'A8AFB5',
	// 	isColorTile: true
	// },
	{
		name: 'Error border',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
];
export const passwordFieldStylesDarkErrorTheme = [
	{
		name: 'Progress bar',
		property: 'background color',
		value: 'FF8593',
		isColorTile: true,
	},
];
export const passwordFieldStylesDarkWarningTheme = [
	{
		name: 'Progress bar',
		property: 'background color',
		value: 'F6C664',
		isColorTile: true,
	},
];
export const passwordFieldStylesDarkSuccessTheme = [
	{
		name: 'Progress bar',
		property: 'background color',
		value: '7FC5A2',
		isColorTile: true,
	},
];
export const passwordFieldStylesTypography = [
	{
		name: 'Text (label)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Text (input)',
		property: 'font-family',
		value: 'Fira Code',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Text (error)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '16px',
		isColorTile: false,
	}
];
export const passwordFieldStylesStructure = [
	{
		name: 'Container',
		property: 'min-height',
		value: '30px',
		isColorTile: false
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	// {
	// 	name: 'Progress bar',
	// 	property: 'height',
	// 	value: '4px',
	// 	isColorTile: false,
	// },
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const passwordFieldStylesDefaultThemeOld = [
	{
		name: 'Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Label Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input Value Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icons (Inside Input Box)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Error Border',
		property: 'border-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Error Text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icons (Inside Input Box): hover',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Border: focus / active',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const passwordFieldStylesDefaultThemePasswordStrength = [
	{
		name: 'Password Strength: Weak',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Password Strength: Okay',
		property: 'background-color',
		value: 'C67839',
		isColorTile: true,
	},
	{
		name: 'Password Strength: Good',
		property: 'background-color',
		value: 'C6B539',
		isColorTile: true,
	},
	{
		name: 'Password Strength: Strong',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
];

export const passwordFieldStylesDarkThemeOld = [
	{
		name: 'Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Label Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input Value Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icons (Inside Input Box)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Error Border',
		property: 'border-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Error Text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icons (Inside Input Box): hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Border: focus / active',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const passwordFieldStylesDarkThemePasswordStrength = [
	{
		name: 'Password Strength: Weak',
		property: 'background-color',
		value: '783A3B',
		isColorTile: true,
	},
	{
		name: 'Password Strength: Okay',
		property: 'background-color',
		value: 'C67839',
		isColorTile: true,
	},
	{
		name: 'Password Strength: Good',
		property: 'background-color',
		value: 'C6B539',
		isColorTile: true,
	},
	{
		name: 'Password Strength: Strong',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
];

export const passwordFieldStylesTypographyOld = [
	{
		name: 'Input Text: masked',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Input Text: unmasked',
		property: 'font-family',
		value: '"Bitstream Vera Sans Mono", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'letter-spacing',
		value: '1.5px',
		isColorTile: false,
	},
];

export const passwordFieldStylesStructureOld = [
	{
		name: 'Input: Focus',
		property: 'outline-offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'outline-width',
		value: '1px',
		isColorTile: false,
	},
];

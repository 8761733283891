import {
	Component,
	ViewChild,
	Input,
	Output,
	EventEmitter,
	ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import _ from 'lodash';

@Component({
	selector: 'app-icons-detail',
	templateUrl: './icons-detail.component.html',
	styleUrls: ['./icons-detail.component.less'],
})
export class IconsDetailComponent {
	@Input() iconData: any;
	@Output() closeEvent = new EventEmitter<boolean>();
	@ViewChild('demoSize') demoSize!: ElementRef;

	public size = 'demoSize48';
	public icons = ShieldIcons;

	onClose() {
		this.closeEvent.emit(true);
	}

	public inputDropDownShown = false;

	constructor(private clipboardService: ClipboardService) {}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public copyContentToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'name',
		});
	}

	public copyExampleToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'code',
		});
	}

	public iconLookup(icon: any): ShieldIcons.SVGIcon {
		const tsName = _.camelCase(icon.name);
		return ShieldIcons[tsName as keyof typeof ShieldIcons];
	}

	public getIconName(value: string): string {
		console.log(value);
		return _.camelCase(value);
	}

	public onSizeSelected(): void {
		this.size = this.demoSize.nativeElement.value;
	}

	public getCodeString(): string {
		return (
			'<kendo-svg-icon [icon]="icons.' +
			this.getIconName(this.iconData.name) +
			'"></kendo-svg-icon>'
		);
	}
}

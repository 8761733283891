<ul class="color-tile-list" (clickOutside)="onUnSelect()">
    <li class="color-tile-list-item" (click)="copyToClipboard(colorData.name)">
        <span class="color-tile-list-item-label">Copy Name: </span>{{colorData.name}}
    </li>
    <li class="color-tile-list-item" (click)="copyToClipboard(colorData.hex)">
        <span class="color-tile-list-item-label">Copy Hex: </span>{{colorData.hex}}
    </li>
    <li class="color-tile-list-item" (click)="copyToClipboard(colorData.rgb)">
        <span class="color-tile-list-item-label">Copy RGB: </span>{{colorData.rgb}}
    </li>
    <li class="color-tile-list-item" (click)="copyToClipboard(colorData.hsl)">
        <span class="color-tile-list-item-label">Copy HSL: </span>{{colorData.hsl}}
    </li>
    <li class="color-tile-list-item" (click)="copyToClipboard(colorData.token)">
        <span class="color-tile-list-item-label">Copy Token: </span>{{colorData.token}}
    </li>
</ul>
// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const dataGridStylesDefaultTheme = [
	{
		name: 'Header Background',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Header Border',
		property: 'border-color',
		value: '7C8790',
		isColorTile: true,
	},
	{
		name: 'Header Focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (alternative)',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (selected)',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Grid Row Border',
		property: 'border-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Grid Row Focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
];

export const dataGridStylesDarkTheme = [
	{
		name: 'Header Background',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Header Border',
		property: 'border-color',
		value: '7C8790',
		isColorTile: true,
	},
	{
		name: 'Header Focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (alternative)',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Grid Row Background (selected)',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Grid Row Border',
		property: 'border-color',
		value: '515F6B',
		isColorTile: true,
	},
	{
		name: 'Grid Row Focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
];

export const dataGridStylesTypography = [
	{
		name: 'Column header text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: 'Item count text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: 'Grid cell text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const dataGridStylesStructure = [
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: 'Icon Menu',
		property: 'justify-content',
		value: 'flex-end',
		isColorTile: false,
	},
	{
		name: 'Grid cell focus',
		property: 'outline-offset',
		value: '-4px',
		isColorTile: false,
	},
	{
		name: 'First Header Row Cell',
		property: 'border-left-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},

	{
		name: 'Inner Header Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},

	{
		name: 'Last Header Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: 'Grid Body',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	
	{
		name: 'Data Row Cell',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	
	{
		name: 'First Data Row Cell',
		property: 'border-left-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},

	{
		name: 'Inner Data Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},

	{
		name: 'last Data Row Cell',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '0px',
		isColorTile: false,
	},

	{
		name: 'Grid Cell Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
];

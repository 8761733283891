import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCircleQuestion, shLink } from '@beyondtrust/shield-icons';
import { ValueAxis, SeriesNotes, SeriesType, CategoryAxisLabels, LegendItemVisualArgs } from '@progress/kendo-angular-charts';
import { Element, geometry, Group, Path, Rect as RectShape, Text } from '@progress/kendo-drawing';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
const { Point, Rect, Size } = geometry;

// Data Sources
import {
	tooltipStylesDefaultTheme,
	tooltipStylesDarkTheme,
	tooltipStylesTypography,
	tooltipStylesStructure,
} from './tooltip-styles';


export type DataModel = {
	categoryName: string;
	labelName: string;
	data: number;
	colorString: string;
  };


@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.less'],
})
export class TooltipComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;
	public questionMark = shCircleQuestion;

	// Imported Style Variables
	public stylesDefault = tooltipStylesDefaultTheme;
	public stylesDark = tooltipStylesDarkTheme;
	public stylesTypography = tooltipStylesTypography;
	public stylesStructure = tooltipStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Show/hide',
					value: 'show-hide',
				},
				{
					name: 'Animation',
					value: 'animation',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Chart tooltips',
					value: 'chart-tooltips',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Accessibility',
					value: 'accessibility',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
				{
					name: 'Tooltip or help text',
					value: 'tooltip-or-help-text',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public selectedBarType: SeriesType = 'column';
	public stackFlag: boolean = false;
	public notes: SeriesNotes = {
	  label: {
		position: 'outside',
		font: '12px Arial'
	  },
	  line: {
		width: 0
	  },
	  icon: {
		visible: false
	  },
	  position: 'bottom'
	};
	public labelVisual(e: { dataItem: DataModel }) {
	  return `${e.dataItem.data}`;
	}
	public valueAxes: ValueAxis[] = [
	  {
		name: 'quantity',
		title: { text: 'Quantity', font: '12px Arial' }
	  }
	];
	public labelOptions: CategoryAxisLabels = {
	  margin: {
		top: 30
	  },
	  padding: {
		left: 40
	  }
	};
	public labelsVisual(args: LegendItemVisualArgs): Element {
	  const pathColor = args.series.data[0].colorString;
	  const rectOptions = {
		stroke: {
		  color: pathColor,
		  width: 1
		},
		fill: {
		  color: pathColor,
		  opacity: 0.5
		}
	  };
	  const rectGeometry = new Rect(new Point(0, 0), new Size(25, 10));
	  const rect: RectShape = new RectShape(rectGeometry, rectOptions);
	  const lineThrough = new Path({
		stroke: {
		  color: args.options.labels.color,
		  width: 3
		}
	  });
	  const labelText1 = args.series.name;
	  const labelFont = args.options.labels.font;
	  const fontColor = args.options.labels.color;
	  const textOptions = {
		font: labelFont,
		fill: { color: fontColor }
	  };
	  const text1 = new Text(labelText1, new Point(30, -5), textOptions);
	  const textBox1 = text1.bbox();
	  const labelLength = textBox1.size.width;
	  const group = new Group();
	  group.append(rect, text1);
	  if (!args.active) {
		lineThrough.moveTo(30, 5).lineTo(labelLength, 5).close();
		group.append(rect, text1, lineThrough);
		group.opacity(0.5);
	  }
	  return group;
	}
	public defaultDarkSeries: DataModel[] = [
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-01',
		  data: 92,
		  colorString: '#6699FF'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-02',
		  data: 140,
		  colorString: '#FC814C'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-03',
		  data: 199,
		  colorString: '#99F99F'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-04',
		  data: 420,
		  colorString: '#CC99FF'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-05',
		  data: 500,
		  colorString: '#FFD35B'
		},
		{
		  categoryName: 'Category 1',
		  labelName: 'Data-06',
		  data: 295,
		  colorString: '#FF551E'
		},
	];

	public groupedData = groupBy(this.defaultDarkSeries, [{ field: 'labelName' }]) as GroupResult[];

	public defaultTooltipHTML = `
<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-top"></kendo-button>
<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-right"></kendo-button>
<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-bottom"></kendo-button>
<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-left"></kendo-button>
`;
	public chartTooltipHTML = `
<kendo-chart
	[transitions]="true"
	[pannable]="false"
	[zoomable]="false"
	[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
	[chartArea]="{height: 400, width: 600}">
	<kendo-chart-value-axis>
		<kendo-chart-value-axis-item *ngFor="let item of valueAxes" [name]="item.name" [title]="item.title"> </kendo-chart-value-axis-item>
	</kendo-chart-value-axis>
	<kendo-chart-category-axis>
		<kendo-chart-category-axis-item [labels]="labelOptions"> </kendo-chart-category-axis-item>
	</kendo-chart-category-axis>
	<kendo-chart-legend position="right">
		<kendo-chart-legend-item [visual]="labelsVisual" cursor="pointer"> </kendo-chart-legend-item>
	</kendo-chart-legend>
	<kendo-chart-series>
		<kendo-chart-series-item
		[opacity]="0.5"
		*ngFor="let groupedResult of groupedData"
		[data]="groupedResult.items"
		[name]="groupedResult.value"
		field="data"
		categoryField="categoryName"
		noteTextField="labelName"
		colorField="colorString"
		[notes]="notes"
		[type]="selectedBarType"
		[stack]="stackFlag">
			<ng-container *ngIf="!stackFlag">
				<kendo-chart-series-item-labels [content]="labelVisual" [background]="'transparent'"> </kendo-chart-series-item-labels>
			</ng-container>
			<kendo-chart-series-item-tooltip>
				<ng-template let-category="category" let-value="value" let-series="series">
					<div class="toolTip">
						<div>{{ category }}</div>
						<div>{{ series.name }}</div>
						<div>{{ value }}</div>
					</div>
				</ng-template>
			</kendo-chart-series-item-tooltip>
		</kendo-chart-series-item>
	</kendo-chart-series>
</kendo-chart>`;
}

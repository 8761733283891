import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { SVGIcon } from '@beyondtrust/shield-icons';

import {
	tagStylesDefaultTheme,
	tagStylesDefaultThemeLowSeverity,
	tagStylesDefaultThemeModerateSeverity,
	tagStylesDefaultThemeHighSeverity,
	tagStylesDefaultThemeCriticalSeverity,
	tagStylesDarkTheme,
	tagStylesDarkThemeLowSeverity,
	tagStylesDarkThemeModerateSeverity,
	tagStylesDarkThemeHighSeverity,
	tagStylesDarkThemeCriticalSeverity,
	tagStylesTypography,
	tagStylesStructure,
} from './tag-styles';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';

@Component({
	selector: 'app-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.less'],
})
export class TagComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	// Imported Style Variables
	public stylesDefault = tagStylesDefaultTheme;
	public stylesDefaultLowSeverity = tagStylesDefaultThemeLowSeverity;
	public stylesDefaultModerateSeverity =
		tagStylesDefaultThemeModerateSeverity;
	public stylesDefaultHighSeverity = tagStylesDefaultThemeHighSeverity;
	public stylesDefaultCriticalSeverity =
		tagStylesDefaultThemeCriticalSeverity;

	public stylesDark = tagStylesDarkTheme;
	public stylesDarkLowSeverity = tagStylesDarkThemeLowSeverity;
	public stylesDarkModerateSeverity = tagStylesDarkThemeModerateSeverity;
	public stylesDarkHighSeverity = tagStylesDarkThemeHighSeverity;
	public stylesDarkCriticalSeverity = tagStylesDarkThemeCriticalSeverity;
	public stylesTypography = tagStylesTypography;
	public stylesStructure = tagStylesStructure;
	public linkIcon = ShieldIcons.shLink;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				// {
				// 	name: 'Getting started',
				// 	value: 'started',
				// },
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Remove',
					value: 'remove',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Severity',
					value: 'severity',
				},
				{
					name: 'Filter',
					value: 'filter',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Icons',
					value: 'icons',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public xIcon = ShieldIcons.shCircleXmark;
	public icons: { name: string; icon: SVGIcon | null }[] = [
		{
			name: 'No Icon',
			icon: null,
		},
		{
			name: 'windows',
			icon: ShieldIcons.shWindows,
		},
		{
			name: 'linux',
			icon: ShieldIcons.shLinux,
		},
		{
			name: 'apple',
			icon: ShieldIcons.shApple,
		},
		{
			name: 'check',
			icon: ShieldIcons.shCheck,
		},
		{
			name: 'question-circle',
			icon: ShieldIcons.shCircleQuestion,
		},
		{
			name: 'plus-circle',
			icon: ShieldIcons.shCirclePlus,
		},
		{
			name: 'warning',
			icon: ShieldIcons.shTriangleExclamation,
		},
	];
	public defaultTags: any[] = [];
	public severityTags: any[] = [];
	public formTags = [];
	public selectedIcon: any = this.icons[0];
	public newTag = {
		label: 'New Tag',
		removable: false,
		icon: null,
		tooltipLabel: null,
	};

	public resetTags() {
		this.defaultTags = [
			{
				label: 'iPhone',
				removable: true,
				icon: ShieldIcons.shApple,
				tooltipLabel: 'Apple',
			},
			{
				label: 'Computer',
				removable: false,
				icon: ShieldIcons.shWindows,
				tooltipLabel: 'Windows',
			},
		];
		this.severityTags = [
			{
				label: 'Low',
				removable: false,
				class: "sh-tag-severity--low"
			},
			{
				label: 'Moderate',
				removable: true,
				class: "sh-tag-severity--moderate"
			},
			{
				label: 'High',
				removable: false,
				class: "sh-tag-severity--high"
			},
			{
				label: 'Critical',
				removable: true,
				class: "sh-tag-severity--critical"
			}
		];
	}

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		this.resetTags();
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
		this.formTags = JSON.parse(JSON.stringify(this.defaultTags));
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}
	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}
	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	addFormTag() {
		if (!this.newTag.label) {
			return;
		}
		this.newTag.icon = !!this.selectedIcon ? this.selectedIcon.icon : null;
		this.formTags.push(this.newTag);
		this.newTag = {
			label: 'New Tag',
			icon: null,
			removable: false,
			tooltipLabel: null,
		};
		this.selectedIcon = this.icons[0];
	}

	removeTag(tag): void {
		this.defaultTags.splice(this.defaultTags.indexOf(tag), 1);
	}

	removeSeverityTag(tag): void {
		this.severityTags.splice(this.severityTags.indexOf(tag), 1);
	}

	removeFormTag(tag): void {
		this.formTags.splice(this.formTags.indexOf(tag), 1);
	}

	tagsHTML = `
<ng-template #tooltipTemplate let-anchor>
	<ng-container *ngIf="anchor.nativeElement.closest('.k-chip') as tag">
		<span *ngIf="anchor.nativeElement.classList.contains('k-chip-icon')">
			{{ tag.getAttribute('tooltip-label') }}
		</span>
		<span *ngIf="anchor.nativeElement.closest('.k-chip-remove-action') !== null">
			Remove tag
		</span>
	</ng-container>
</ng-template>
<div kendoTooltip [tooltipTemplate]="tooltipTemplate" filter=".k-svg-icon">
	<kendo-chip
		label="iPhone"
		[svgIcon]="appleIcon"
		tooltip-label="Apple"
		[removable]="true"
		[removeSvgIcon]="clearIcon"
		(remove)="removeTag($event)">
	</kendo-chip>
</div>`;
}

import { Component, Input, Output,  EventEmitter } from '@angular/core';
import { ClipboardService } from 'src/app/services/clipboard.service';

@Component({
  selector: 'app-color-tile-option',
  templateUrl: './color-tile-option.component.html',
  styleUrl: './color-tile-option.component.less'
})
export class ColorTileOptionComponent {
  @Input() colorData;
  @Output() ItemClickedOutside = new EventEmitter<boolean>();

  initialized: boolean;

  constructor(private clipboardService: ClipboardService) {}

  ngOnInit(): void {
    this.initialized = true;
  }

  public onUnSelect(): void {
    if (!this.initialized) {
      this.ItemClickedOutside.emit(false);
    }
    this.initialized = false;
  }
  
  public copyToClipboard(colorValue): void {
		this.clipboardService.createClipboard({
			value: colorValue,
			type: 'color',
		});
    this.ItemClickedOutside.emit(false);
	}
}

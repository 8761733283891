import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { searchData } from 'src/app/common/search/search-data';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-accessibility',
	templateUrl: './accessibility.component.html',
	styleUrls: ['./accessibility.component.less'],
})
export class AccessibilityComponent {
	public pageData = searchData;
	public currPageData = [];
	public currVisible = 'overview';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},

		{
			name: 'Why accessibility matters',
			value: 'why-accessibility-matters',
			empty: false,
			children: [],
		},
		{
			name: 'Accessibility laws and guidelines',
			value: 'laws-and-guidelines',
			empty: false,
			children: [],
		},
		{
			name: 'Accessibility criteria',
			value: 'criteria',
			empty: false,
			children: [],
		},
		{
			name: 'Accessibility principles',
			value: 'principles',
			empty: false,
			children: [],
		},
		{
			name: 'Designing for accessibility',
			value: 'designing',
			empty: false,
			children: [],
		},
		{
			name: 'Developing for accessibility',
			value: 'developing',
			empty: false,
			children: [],
		},
		{
			name: 'Writing for accessibility',
			value: 'writing',
			empty: false,
			children: [],
		},

		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}

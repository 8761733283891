import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { SVGIcon } from '@beyondtrust/shield-icons';

// Data Sources
import {
	buttonStylesDefaultThemePrimary,
	buttonStylesDefaultThemeSecondary,
	buttonStylesDefaultThemeDanger,
	buttonStylesDefaultThemeGhost,
	buttonStylesDefaultThemeText,
	buttonStylesDefaultThemeTextSecondary,
	buttonStylesDefaultThemeTextIcon,
	buttonStylesDefaultThemeIcon,
	buttonStylesDarkThemePrimary,
	buttonStylesDarkThemeSecondary,
	buttonStylesDarkThemeDanger,
	buttonStylesDarkThemeGhost,
	buttonStylesDarkThemeText,
	buttonStylesDarkThemeTextSecondary,
	buttonStylesDarkThemeTextIcon,
	buttonStylesDarkThemeIcon,
	buttonStylesTypography,
	buttonStylesStructure,
} from './button-styles';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.less'],
})
export class ButtonComponent implements OnDestroy {
	icons = ShieldIcons;
	public github: SVGIcon = ShieldIcons.shGithub;
	public left: SVGIcon = ShieldIcons.shArrowLeft;
	public right: SVGIcon = ShieldIcons.shArrowRight;
	public question: SVGIcon = ShieldIcons.shCircleQuestion;
	public print: SVGIcon = ShieldIcons.shPrint;
	public switchValue = false;
	public disabled = false;
	public buttonHTML: any = `
<button kendoButton themeColor="primary">Primary</button>

<button kendoButton themeColor="secondary">Secondary</button>

<button kendoButton themeColor="tertiary">Danger</button>

<button kendoButton fillMode="outline">Ghost</button>

<button kendoButton themeColor="primary" fillMode="flat">Primary text</button>

<button kendoButton themeColor="primary" fillMode="flat" class="button-text-secondary">Secondary text</button>

<kendo-button 
	[svgIcon]="right" 
	themeColor="primary" 
	fillMode="flat">
	Text Icon (Left)
</kendo-button>

<kendo-button 
	[svgIcon]="left" 
	themeColor="primary" 
	fillMode="flat"
	class="button-icon-right">
	Text Icon (Right)
</kendo-button>

<kendo-button [svgIcon]="profile" title="User Profile"></kendo-button>
`;

	public currLive = 'filled-primary';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefaultPrimary = buttonStylesDefaultThemePrimary;
	public stylesDefaultSecondary = buttonStylesDefaultThemeSecondary;
	public stylesDefaultDanger = buttonStylesDefaultThemeDanger;
	public stylesDefaultGhost = buttonStylesDefaultThemeGhost;
	public stylesDefaultText = buttonStylesDefaultThemeText;
	public stylesDefaultTextSecondary = buttonStylesDefaultThemeTextSecondary;
	public stylesDefaultTextIcon = buttonStylesDefaultThemeTextIcon;
	public stylesDefaultIcon = buttonStylesDefaultThemeIcon;
	public stylesDarkPrimary = buttonStylesDarkThemePrimary;
	public stylesDarkSecondary = buttonStylesDarkThemeSecondary;
	public stylesDarkDanger = buttonStylesDarkThemeDanger;
	public stylesDarkGhost = buttonStylesDarkThemeGhost;
	public stylesDarkText = buttonStylesDarkThemeText;
	public stylesDarkTextSecondary = buttonStylesDarkThemeTextSecondary;
	public stylesDarkTextIcon = buttonStylesDarkThemeTextIcon;
	public stylesDarkIcon = buttonStylesDarkThemeIcon;
	public stylesTypography = buttonStylesTypography;
	public stylesStructure = buttonStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Primary',
					value: 'primary',
				},
				{
					name: 'Secondary',
					value: 'secondary',
				},
				{
					name: 'Danger',
					value: 'danger',
				},
				{
					name: 'Ghost',
					value: 'ghost',
				},
				{
					name: 'Primary text',
					value: 'primary-text',
				},
				{
					name: 'Secondary text',
					value: 'secondary-text',
				},
				{
					name: 'Text with icon',
					value: 'text-icon',
				},
				{
					name: 'Icon only',
					value: 'icon-only',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Wrapping',
					value: 'wrapping',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}
	
	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeEnabled(event): void {
		this.disabled = event;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}

// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const checkboxStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container (selected)',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected)',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Container (selected): hover',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): hover',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'border-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container (selected): focus',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): focus',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Text (supporting content)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	}
];

export const checkboxStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Container (selected)',
		property: 'background-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected)',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container (selected): hover',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): hover',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container (selected): focus',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): focus',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text (supporting content)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	}
];

export const checkboxStylesTypographyAll = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const checkboxStylesTypographySupporting = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '16px',
		isColorTile: false,
	},
];

export const checkboxStylesStructureAll = [
	{
		name: 'Text (label)',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Text (supporting)',
		property: 'margin-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];

export const checkboxStylesStructureSupporting = [
	{
		name: 'Supporting Text',
		property: 'margin-left',
		value: '26px',
		isColorTile: false,
	},
];

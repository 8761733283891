export const data = [
	{
	  EventTime: '06/13/2024  18:13:35 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  17:43:22 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:43:19 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:41:28 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:41:23 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:41:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:41:14 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:41:13 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:37:45 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:37:41 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:37:38 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'PowerShell',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:37:26 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:37:22 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:37:09 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:24:36 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:15:10 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  17:13:27 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:10:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® .NET Framework',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:10:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:10:14 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:10:13 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® .NET Framework',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:09:42 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:09:36 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:09:35 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:09:06 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:09:05 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:07:05 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:07:03 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:06:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:05:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:59 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:55 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:54 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:53 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:52 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:50 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:45 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:35 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:25 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:16 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:15 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:14 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:04:09 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:39 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:36 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:35 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:34 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:33 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:32 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:31 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:29 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:11 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:08 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:08 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:08 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:06 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:03 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:02 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:02 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:01 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:03:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:02:59 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:02:57 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:02:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  17:01:52 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:58:44 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:55:25 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:52:13 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'TeamViewer',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:51:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:51:07 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:51:04 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:50:44 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:50:44 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:50:36 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:48:12 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:46:57 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:40:31 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:40:10 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:39:13 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:39:09 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:39:09 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:39:08 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:39:04 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:39:01 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:39:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:38:28 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:38:01 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:38:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:37:40 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:37:38 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:37:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:37:22 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:37:22 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  16:37:22 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:52:56 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  15:41:53 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  15:41:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:41:16 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:33:45 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:33:39 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:32:45 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Sysinternals Procmon',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:32:40 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Sysinternals Procmon',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:31:44 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:31:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:31:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:26:52 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:26:51 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  15:25:19 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  15:24:56 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  15:23:28 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/13/2024  13:51:55 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  13:10:24 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  13:10:23 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/13/2024  03:56:40 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  19:51:25 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  18:42:25 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  18:33:45 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  18:33:20 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  18:33:02 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  18:30:13 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  18:25:34 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  18:24:57 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Google Chrome',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  18:16:06 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  18:01:12 +00:00',
	  EventType: 'Process with file',
	  EventAction: 'Blocked',
	  ApplicationName: '--',
	  ApplicationType: 'msc'
	},
	{
	  EventTime: '06/12/2024  17:59:15 +00:00',
	  EventType: 'Process with file',
	  EventAction: 'Blocked',
	  ApplicationName: '--',
	  ApplicationType: 'msc'
	},
	{
	  EventTime: '06/12/2024  17:46:56 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  17:46:15 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  17:42:32 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  17:42:06 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  17:42:04 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  17:42:04 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  17:41:50 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  17:40:44 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  17:13:12 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  16:36:40 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  16:13:12 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  16:12:41 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  15:36:09 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  15:32:36 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  15:32:28 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:31:30 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:31:11 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:19:07 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  15:18:31 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:17:56 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:16:38 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:14:36 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:14:01 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:13:58 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:13:56 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:13:52 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:13:43 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  15:07:46 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/12/2024  14:52:09 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  14:51:57 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  14:51:26 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  14:43:02 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  14:35:59 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  14:33:30 +00:00',
	  EventType: 'Process with file',
	  EventAction: 'Cancelled',
	  ApplicationName: '--',
	  ApplicationType: 'msc'
	},
	{
	  EventTime: '06/12/2024  14:32:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  14:32:05 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  14:20:40 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  13:16:12 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  13:16:10 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  11:55:48 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:48 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:48 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  11:55:02 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:59:04 +00:00',
	  EventType: 'Process',
	  EventAction: 'Blocked',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  10:58:54 +00:00',
	  EventType: 'Process',
	  EventAction: 'Blocked',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  10:47:54 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  10:46:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  10:46:07 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:45:34 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:45:12 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:19:08 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  10:17:02 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:16:51 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:16:33 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:10:12 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:10:12 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:10:05 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:08:06 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  10:02:09 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  04:30:59 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  04:30:35 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  03:50:24 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  03:46:44 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  01:12:23 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  00:50:46 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/12/2024  00:11:49 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/12/2024  00:11:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  18:04:30 +00:00',
	  EventType: 'Process with file',
	  EventAction: 'Cancelled',
	  ApplicationName: '--',
	  ApplicationType: 'msc'
	},
	{
	  EventTime: '06/11/2024  17:54:53 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  16:28:20 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  15:30:37 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  14:41:16 +00:00',
	  EventType: 'Process with file',
	  EventAction: 'Cancelled',
	  ApplicationName: '--',
	  ApplicationType: 'msc'
	},
	{
	  EventTime: '06/11/2024  13:49:26 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:13:48 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:13:48 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:13:38 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:13:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:13:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:13:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:13:27 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  11:12:58 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  09:39:50 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  09:39:29 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  09:38:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  09:37:57 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  09:32:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  09:31:53 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  09:04:26 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  09:04:21 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  09:03:40 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  08:43:05 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  08:42:27 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  08:42:11 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  08:41:42 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  08:14:55 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  08:14:55 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  06:48:28 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/11/2024  01:12:23 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  00:12:22 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  00:12:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/11/2024  00:05:50 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  21:52:10 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:50:08 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:49:58 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:45:53 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  20:28:24 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  20:28:22 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  20:07:24 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:05:35 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'sudo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:05:27 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:05:27 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:04:33 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'sudo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:04:05 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'sudo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:02:49 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'sudo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:02:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  20:02:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  18:20:10 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/10/2024  18:19:46 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/10/2024  18:15:52 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/10/2024  18:15:02 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  18:14:36 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  18:14:20 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  17:54:57 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  16:50:09 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:44:03 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge Update',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:34:28 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:31:06 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  16:31:06 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  16:30:58 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  16:18:17 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:18:11 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:17:44 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:17:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:17:42 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'VLC media player',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:17:42 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:17:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Elevated',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:17:18 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:16:57 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:13:52 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  16:13:44 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:58:55 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:53:45 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:53:18 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:51:20 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:51:08 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:51:00 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:44:03 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge Update',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:40:59 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'BGInfo',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:40:28 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:23:50 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge Update',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:23:09 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:19:53 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:19:53 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:19:52 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:19:52 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'VLC media player',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:19:41 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:18:36 +00:00',
	  EventType: 'Process',
	  EventAction: 'Cancelled',
	  ApplicationName: '--',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:18:19 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:17:30 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge Update',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:17:00 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge Update',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:17:00 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  15:07:12 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge Update',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:06:50 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft Edge Update',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  15:06:50 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  14:53:56 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  14:32:07 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  14:32:05 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  14:32:05 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  13:52:09 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/10/2024  13:18:22 +00:00',
	  EventType: 'Process with file',
	  EventAction: 'Elevated',
	  ApplicationName: '--',
	  ApplicationType: 'msi'
	},
	{
	  EventTime: '06/10/2024  07:15:32 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  07:15:13 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  07:15:08 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  07:11:16 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/10/2024  07:11:16 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/09/2024  16:37:34 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/09/2024  09:46:45 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/08/2024  13:53:22 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/08/2024  09:46:56 +00:00',
	  EventType: 'Unlicensed',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  21:52:13 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  20:52:19 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  17:20:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  17:20:18 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  16:30:53 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  15:34:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  14:55:52 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  13:52:27 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft SharePoint',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  13:52:20 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  13:52:20 +00:00',
	  EventType: 'Process',
	  EventAction: 'Allowed',
	  ApplicationName: 'Microsoft OneDrive',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/07/2024  13:48:38 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  13:48:05 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  13:48:04 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  13:47:58 +00:00',
	  EventType: 'Agent Stop',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  13:43:37 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  13:43:24 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  13:43:23 +00:00',
	  EventType: 'Agent Start',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  11:48:37 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/07/2024  03:02:47 +00:00',
	  EventType: 'User Logon',
	  EventAction: '--',
	  ApplicationName: '--',
	  ApplicationType: '--'
	},
	{
	  EventTime: '06/06/2024  22:23:28 +00:00',
	  EventType: 'COM Class',
	  EventAction: 'Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'com'
	},
	{
	  EventTime: '06/06/2024  21:20:47 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft ® Windows Script Host',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/06/2024  21:20:01 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft ® Windows Script Host',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/06/2024  21:17:43 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft ® Windows Script Host',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/06/2024  21:16:53 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft ® Windows Script Host',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/06/2024  21:16:30 +00:00',
	  EventType: 'Process with file',
	  EventAction: 'Allowed',
	  ApplicationName: '--',
	  ApplicationType: 'vbs'
	},
	{
	  EventTime: '06/06/2024  21:06:33 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/06/2024  21:06:27 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/06/2024  21:05:42 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	},
	{
	  EventTime: '06/06/2024  21:05:42 +00:00',
	  EventType: 'Process',
	  EventAction: 'Self-Elevated',
	  ApplicationName: 'Microsoft® Windows® Operating System',
	  ApplicationType: 'exe'
	}
  ];

// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const textFieldStylesDefaultTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon (clear icon)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon (left of input box text)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon background (circular): hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: '253746',
		isColorTile: true
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icon (clear icon): hover ',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const textFieldStylesDarkTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon (clear icon)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon (left of input box text)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon background (circular): hover',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icon (clear icon): hover ',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
];

export const textFieldStylesTypography = [
	{
		name: 'Text (label)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Text (input)',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Text (instructional and error)',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '16px',
		isColorTile: false,
	}
];

export const textFieldStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '393px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '620px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '30px',
		isColorTile: false
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '16px',
		isColorTile: false,
	},
];

// External Imports
import { Injectable } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

// Internal Imports
import { ClipboardItem } from '../interfaces/clipboard-item';

// Todo: wire this up to local storage and fix up the crud functionality
// make sure there are no mutations by using pure functions

@Injectable({
	providedIn: 'root',
})
export class ClipboardService {
	public clipboardData: ClipboardItem = { type: '', value: '' };
	public currRoute: string;

	constructor(private router: Router) {}

	createClipboard(newClipboardItem: ClipboardItem) {
		if (newClipboardItem.type === 'url') {
			newClipboardItem.value =
				document.getElementsByTagName('base')[0].href +
				this.router.url.substring(1).split('#')[0] +
				'#' +
				newClipboardItem.value;
		}

		if (navigator.clipboard) {
			navigator.clipboard.writeText(newClipboardItem.value).then(
				() => {
					this.clipboardData = newClipboardItem;
					setTimeout(() => {
						this.deleteClipboard();
					}, 3000);
				},
				(error) => {}
			);
		} else {
		}
	}

	deleteClipboard(): void {
		this.clipboardData = {
			type: '',
			value: '',
		};
	}
}

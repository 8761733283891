import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-formatting',
	templateUrl: './formatting.component.html',
	styleUrls: ['./formatting.component.less'],
})
export class FormattingComponent {
	public currVisible = 'overview';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Principles',
			value: 'principles',
			empty: false,
			children: [],
		},
		{
			name: 'Date',
			value: 'date',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Abbreviations',
					value: 'abbreviations',
				},
			],
		},
		{
			name: 'Time',
			value: 'time',
			empty: false,
			children: [],
		},
		{
			name: 'Date and time together',
			value: 'date-and-time',
			empty: false,
			children: [],
		},
		{
			name: 'Date and time ranges',
			value: 'ranges',
			empty: false,
			children: [
				{
					name: 'Date ranges',
					value: 'date',
				},
				{
					name: 'Time ranges',
					value: 'time',
				},
			],
		},
		{
			name: 'Timecode',
			value: 'timecode',
			empty: false,
			children: [],
		},
		{
			name: 'Lists and grids',
			value: 'lists-and-grids',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}

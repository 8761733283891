// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const accordionStylesDefaultTheme = [
	{
		name: 'Container',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container header: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
	{
		name: 'Title text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];
export const accordionStylesDarkTheme = [
	{
		name: 'Container',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Container header: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Title text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
];
export const accordionStylesTypography = [
	{
		name: 'Header title text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];
export const accordionStylesStructure = [
	{
		name: 'Container',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Container (bordered)',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Container (borderless)',
		property: 'border-width',
		value: '0px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Header',
		property: 'padding-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Content area',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus (only header)',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution
export const accordionStylesDefaultThemeDefault = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'border-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const accordionStylesDefaultThemeWide = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'border-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const accordionStylesDarkThemeDefault = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const accordionStylesDarkThemeWide = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const accordionStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '14px',
		isColorTile: false,
	},
];

export const accordionStylesStructureDefault = [
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];

export const accordionStylesStructureWide = [
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];

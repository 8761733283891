// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const buttonStylesDefaultThemePrimary = [
	{
		name: 'Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeDanger = [
	{
		name: 'Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeGhost = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'None - Transparent',
		isColorTile: false,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'border-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text: focus / hover',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeText = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeTextSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeTextIcon = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Icon: focus / active',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const buttonStylesDefaultThemeIcon = [
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Background (circular): hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Icon: focus',
		property: 'outline-color',
		value: '253746',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemePrimary = [
	{
		name: 'Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '066C84',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeDanger = [
	{
		name: 'Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '783A3B',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'A23700',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeGhost = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'None - Transparent',
		isColorTile: false,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text: focus / hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'background-color',
		value: 'None - Transparent',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeText = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeTextSecondary = [
	{
		name: 'Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeTextIcon = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonStylesDarkThemeIcon = [
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Background (circular): hover',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '24px',
		isColorTile: false,
	},
];

export const buttonStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'cursor',
		value: 'pointer',
		isColorTile: false,
	},
	{
		name: 'Container Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];
export const buttonStylesStructureIcon = [
	{
		name: 'Height',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Width',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
];

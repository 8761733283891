// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: badgeStylesStructureWide or buttonStylesDarkThemePrimary

export const previewBadgeStylesDefault = [
	{
		name: 'Text',
		property: 'color',
		value: '8B0E52',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'FDF2FA',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'FCCEEE',
		isColorTile: true,
	},
];

export const newBadgeStylesDefault = [
	{
		name: 'Text',
		property: 'color',
		value: '035030',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'ECFDF3',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'ABEFC6',
		isColorTile: true,
	},
];

export const previewBadgeStylesDark = [
	{
		name: 'Text',
		property: 'color',
		value: '8B0E52',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'FDF2FA',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'FCCEEE',
		isColorTile: true,
	},
];

export const newBadgeStylesDark = [
	{
		name: 'Text',
		property: 'color',
		value: '035030',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'ECFDF3',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'ABEFC6',
		isColorTile: true,
	},
];

export const badgeStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const badgeStylesStructure = [
	{
		name: 'Container',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '2px',
		isColorTile: false,
	},
];

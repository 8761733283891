// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: comboBoxStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const comboBoxStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const comboBoxStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const comboBoxStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const comboBoxStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const comboBoxStylesDefaultThemeOld = [
	{
		name: 'Label Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input Box Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input Box',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Input Box',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input Box Icon (Down Caret)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Error Text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Input Box Error',
		property: 'border-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'List Item',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'List Item Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Selected List Item',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Selected List Item Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'List',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Input Box Icon (Down Caret): hover',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'List Item: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
	{
		name: 'Input Box: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Input Box',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const comboBoxStylesDarkThemeOld = [
	{
		name: 'Label Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input Box Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Input Box',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Input Box',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input Box Icon (Down Caret)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Error Text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Input Box Error',
		property: 'border-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'List Item',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'List Item Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Selected List Item',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Selected List Item Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'List',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input Box Icon (Down Caret): hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'List Item: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input Box: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Input Box',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const comboBoxStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '14px',
		isColorTile: false,
	},
];

export const comboBoxStylesStructureOld = [
	{
		name: 'Container',
		property: 'min-width',
		value: '240px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '400px',
		isColorTile: false,
	},
	{
		name: 'Container: hover',
		property: 'cursor',
		value: 'pointer',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];

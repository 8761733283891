export const data = [
	{
		Name: 'mac-virtual-01',
		Status: 'Disconnected',
		DaysDisconnected: 3,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-01',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-02',
		Status: 'Disconnected',
		DaysDisconnected: 35,
		GroupName: 'Windows Desktops Mgmnt',
		AssignedPolicy: '--',
		PolicyStatus: 'No Policy',
	},
	{
		Name: 'win-server-01',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-02',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-03',
		Status: 'Disconnected',
		DaysDisconnected: 5,
		GroupName: 'Windows Desktops Mgmnt',
		AssignedPolicy: 'Win-Management v3',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-04',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-05',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops Mgmnt',
		AssignedPolicy: 'Win-Management v3',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-06',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-07',
		Status: 'Disconnected',
		DaysDisconnected: 36,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'mac-laptop-01',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Mac Laptops',
		AssignedPolicy: 'Mac-Laptops v4',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-08',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-03',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-04',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-09',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-05',
		Status: 'Disconnected',
		DaysDisconnected: 15,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-06',
		Status: 'Disconnected',
		DaysDisconnected: 38,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-10',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: '--',
		PolicyStatus: 'No Policy',
	},
	{
		Name: 'win-desktop-11',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-12',
		Status: 'Disconnected',
		DaysDisconnected: 13,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-13',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-server-02',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-server-03',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-07',
		Status: 'Disconnected',
		DaysDisconnected: 59,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'Awaiting An Earlier Policy',
	},
	{
		Name: 'win-desktop-14',
		Status: 'Disconnected',
		DaysDisconnected: 41,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: '--',
		PolicyStatus: 'No Policy',
	},
	{
		Name: 'mac-laptop-02',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Mac Laptops',
		AssignedPolicy: 'Mac-Laptops v4',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-15',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Management v3',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-08',
		Status: 'Disconnected',
		DaysDisconnected: 2,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-laptop-03',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Mac Laptops',
		AssignedPolicy: 'Mac-Laptops v4',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-16',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-17',
		Status: 'Disconnected',
		DaysDisconnected: 6,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-server-04',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-server-05',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-18',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-19',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v22',
		PolicyStatus: 'On An Earlier Policy',
	},
	{
		Name: 'win-desktop-20',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-09',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-21',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-22',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-23',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-24',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-25',
		Status: 'Disconnected',
		DaysDisconnected: 3,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'mac-virtual-10',
		Status: 'Disconnected',
		DaysDisconnected: 3,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-26',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-27',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops Mgmnt',
		AssignedPolicy: '--',
		PolicyStatus: 'No Policy',
	},
	{
		Name: 'win-server-06',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-11',
		Status: 'Disconnected',
		DaysDisconnected: 2,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-28',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops Mgmnt',
		AssignedPolicy: 'Win-Management v3',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-29',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-30',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops Mgmnt',
		AssignedPolicy: 'Win-Management v3',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-31',
		Status: 'Disconnected',
		DaysDisconnected: 6,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-32',
		Status: 'Disconnected',
		DaysDisconnected: 36,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'mac-laptop-04',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Mac Laptops',
		AssignedPolicy: 'Mac-Laptops v4',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-33',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-12',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-13',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-34',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-14',
		Status: 'Disconnected',
		DaysDisconnected: 15,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'Mac-virtual-15',
		Status: 'Disconnected',
		DaysDisconnected: 38,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-35',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: '--',
		PolicyStatus: 'No Policy',
	},
	{
		Name: 'win-desktop-36',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-37',
		Status: 'Disconnected',
		DaysDisconnected: 13,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-38',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-server-07',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-server-08',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-16',
		Status: 'Disconnected',
		DaysDisconnected: 59,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'Awaiting An Earlier Policy',
	},
	{
		Name: 'win-desktop-39',
		Status: 'Disconnected',
		DaysDisconnected: 41,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: '--',
		PolicyStatus: 'No Policy',
	},
	{
		Name: 'mac-laptop-05',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Mac Laptops',
		AssignedPolicy: 'Mac-Laptops v4',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-40',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Management v3',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-17',
		Status: 'Disconnected',
		DaysDisconnected: 2,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-laptop-06',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Mac Laptops',
		AssignedPolicy: 'Mac-Laptops v4',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-41',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-42',
		Status: 'Disconnected',
		DaysDisconnected: 6,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-server-09',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-server-10',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Servers',
		AssignedPolicy: 'Win-Servers v11',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-43',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-44',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v22',
		PolicyStatus: 'On An Earlier Policy',
	},
	{
		Name: 'win-desktop-45',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'mac-virtual-18',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Virtual Macs',
		AssignedPolicy: 'Virtual-Macs v31',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-46',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-47',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-48',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops East',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
	{
		Name: 'win-desktop-49',
		Status: 'Connected',
		DaysDisconnected: 0,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'On Latest Policy',
	},
	{
		Name: 'win-desktop-50',
		Status: 'Disconnected',
		DaysDisconnected: 3,
		GroupName: 'Windows Desktops West',
		AssignedPolicy: 'Win-Engineering v23',
		PolicyStatus: 'Awaiting Latest Policy',
	},
]

// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: helpTextStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const helpTextStylesDefaultTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Title text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Body text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];
export const helpTextStylesDarkTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Title text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Body text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];
export const helpTextStylesTypography = [
	{
		name: 'Title text (H4)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Body text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];
export const helpTextStylesStructure = [
	{
		name: 'Container',
		property: 'min-height',
		value: '32px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Title text',
		property: 'margin-bottom',
		value: '8px',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const helpTextStylesDefaultThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const helpTextStylesDarkThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
];

export const helpTextStylesTypographyOld = [
	{
		name: 'Label Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '14px',
		isColorTile: false,
	},
	{
		name: 'Body Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '14px',
		isColorTile: false,
	},
];

export const helpTextStylesStructureOld = [
	{
		name: 'Container',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '440px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Label Text',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Container Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];

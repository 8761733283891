// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: timePickerStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const timePickerStylesDefaultTheme = [
	{
		name: 'Container',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: 'List (hour, minute, or AM/PM) text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Currently hovered list (hour, minute, or AM/PM)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Selected time in list',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'List column header text',
		property: 'color',
		value: '7C878E',
		isColorTile: true,
	}
];
export const timePickerStylesDarkTheme = [
	{
		name: 'Container',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'List (hour, minute, or AM/PM) text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Currently hovered list (hour, minute, or AM/PM)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Selected time in list',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'List column header text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	}
];
export const timePickerStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'List column header text',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: 'Current selection text (top left)',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	}
];
export const timePickerStylesStructure = [
	{
		name: 'Container',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Text field container',
		property: 'width',
		value: 'initial (approx. 210px)',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const timePickerStylesDefaultThemeOld = [
	{
		name: 'Background(header area)',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: 'Background(controls area)',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: 'Background(time area)',
		property: 'background-color',
		value: 'EEEEEE',
		isColorTile: true,
	},
	{
		name: 'Background(selected time area)',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Selected Time Text (in header)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Border: focus/active',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const timePickerStylesDarkThemeOld = [
	{
		name: 'Background(header area)',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Background(controls area)',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Background(time area)',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Background(selected time area)',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Selected Time Text (in header)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Border: focus/active',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const timePickerStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const textFieldStylesDarkTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon (right of input box text)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon background (circular): hover',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icon (clock icon): hover ',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
];

export const textFieldStylesDefaultTheme = [
	{
		name: 'Label text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input box text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon (right of input box text)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon background (circular): hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: '253746',
		isColorTile: true
	},
	{
		name: 'Error border',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Error text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icon (clock icon): hover ',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const timePickerStylesStructureOld = [];

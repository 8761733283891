import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	labelValueStylesDefaultTheme,
	labelValueStylesDarkTheme,
	labelValueStylesTypography,
	labelValueStylesTypographyLabel,
	labelValueStylesTypographyValue,
	labelValueStylesStructure,
} from './label-value-styles';
import { shLink, shWindows } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-label-value',
	templateUrl: './label-value.component.html',
	styleUrls: ['./label-value.component.less'],
})
export class LabelValueComponent implements OnDestroy {
	public currLive = 'emphasized-labels';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;
	public windows = shWindows;

	// Imported Style Variables
	public stylesDefault = labelValueStylesDefaultTheme;
	public stylesDark = labelValueStylesDarkTheme;
	public stylesTypography = labelValueStylesTypography;
	public stylesTypographyLabel = labelValueStylesTypographyLabel;
	public stylesTypographyValue = labelValueStylesTypographyValue;
	public stylesStructure = labelValueStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Emphasized label',
					value: 'emphasized-label',
				},
				{
					name: 'Emphasized value',
					value: 'emphasized-value',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public emLabelsHTML = `
<dl class="sh-label-value-pair sh-pair-em-label">
  <dt class="sh-pair-label">Amend Token Resource ID</dt>
  <dd class="sh-pair-value">https://api.ic3.avecto.com</dd>
</dl>`;
	public emValuesHTML = `
<dl class="sh-label-value-pair sh-pair-em-value">
  <dt class="sh-pair-label">User Type</dt>
  <dd class="sh-pair-value">Advanced User</dd>
</dl>`;
	public withIconHTML = `
<dl class="sh-label-value-pair sh-pair-em-label">
  <dt class="sh-pair-label">User Id</dt>
  <dd class="sh-pair-value">
    <kendo-svg-icon [icon]="windows" class="sh-icon--windows"></kendo-svg-icon>
    Frank Fields
  </dd>
</dl>`;
}

// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: timelineStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const timelineStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const timelineStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const timelineStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const timelineStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const timelineStylesDefaultThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Status Container',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Description Container',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const timelineStylesDarkThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Status Container',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Description Container',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '15293A',
		isColorTile: true,
	},
];

export const timelineStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Label Text',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
];

export const timelineStylesStructureOld = [
	{
		name: 'Description Container',
		property: 'margin-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top/padding-bottom',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Status Container',
		property: 'max-width',
		value: '100px',
		isColorTile: false,
	},
	{
		name: 'Status Container (Icon)',
		property: 'height',
		value: '60px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '30px',
		isColorTile: false,
	},
	{
		name: 'Timestamp',
		property: 'margin-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left/padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Label',
		property: 'margin-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Description',
		property: 'margin-bottom',
		value: '8px',
		isColorTile: false,
	},
];

// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const iconStylesDefaultTheme = [
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];

export const iconStylesDefaultThemeStatus = [
	{
		name: 'Success',
		property: 'color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Warning',
		property: 'color',
		value: 'FF9C00',
		isColorTile: true,
	},
	{
		name: 'Error',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Informational',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const iconStylesDarkTheme = [
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const iconStylesDarkThemeStatus = [
	{
		name: 'Success',
		property: 'color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Warning',
		property: 'color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Error',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Informational',
		property: 'color',
		value: '6699CC',
		isColorTile: true,
	},
];

export const iconStylesTypography = [];

export const iconStylesStructure = [
	{
		name: 'Icon',
		property: 'height',
		value: '16px or 24px or 48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '16px or 24px or 48px',
		isColorTile: false,
	},
];

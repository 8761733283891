import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

// Kendo imports
import { process } from '@progress/kendo-data-query';
import {
	DataBindingDirective,
	RowArgs,
	SelectAllCheckboxState,
	SelectableSettings,
	SelectionEvent,
} from '@progress/kendo-angular-grid';
import { IconThemeColor } from '@progress/kendo-angular-icons';
import { SVGIcon } from '@progress/kendo-svg-icons';

// Styles imports
import {
	checkboxStylesDefaultTheme,
	checkboxStylesDarkTheme,
} from '../checkbox/checkbox-styles';
import {
	dataGridStylesDefaultTheme,
	dataGridStylesDarkTheme,
	dataGridStylesTypography,
	dataGridStylesStructure,
} from './data-grid-styles';
import {
	paginationStylesDefaultTheme,
	paginationStylesDarkTheme,
} from '../pagination/pagination-styles';
import {
	iconStylesDefaultTheme,
	iconStylesDarkTheme,
} from '../icon/icon-styles';
import { textLinkStylesDarkTheme } from '../text-link/text-link-styles';

// Shield Icons
import * as ShieldIcons from '@beyondtrust/shield-icons';

import { ChangelogService } from 'src/app/services/changelog.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { products } from './products';

const warningDuotoneIcon = {
	name: 'shCircleWarningDuotone',
	content:
		'<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0423 3.76202C7.15373 3.76202 3.19037 7.72538 3.19037 12.614C3.19037 17.5026 7.15373 21.466 12.0423 21.466C16.931 21.466 20.8943 17.503 20.8943 12.614C20.8943 7.72496 16.931 3.76202 12.0423 3.76202ZM12.0423 18.1466C11.4304 18.1466 10.9357 17.6511 10.9357 17.04C10.9357 16.4289 11.4304 15.9334 12.0423 15.9334C12.6543 15.9334 13.1489 16.4285 13.1489 17.04C13.1489 17.6515 12.6543 18.1466 12.0423 18.1466ZM13.1489 12.614C13.1489 13.2255 12.6543 13.7206 12.0423 13.7206C11.4304 13.7206 10.9357 13.2251 10.9357 12.614V8.18801C10.9357 7.57736 11.4304 7.08141 12.0423 7.08141C12.6543 7.08141 13.1489 7.57736 13.1489 8.18801V12.614Z" fill="#FF9C00"/><g clip-path="url(#clip0_10636_275)"><path d="M22.3951 14.8185C23.4618 9.11843 19.7058 3.63288 14.0058 2.56615C8.30573 1.49942 2.82018 5.25545 1.75345 10.9555C0.686718 16.6555 4.44275 22.1411 10.1428 23.2078C15.8428 24.2745 21.3284 20.5185 22.3951 14.8185Z" fill="#FF9C00"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.0779 19.4492C11.3541 19.4492 10.7609 18.856 10.7609 18.1323C10.7609 17.4085 11.3423 16.8153 12.0779 16.8153C12.8135 16.8153 13.3948 17.4085 13.3948 18.1323C13.3948 18.856 12.8135 19.4492 12.0779 19.4492Z" fill="#253746"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3948 12.8882C13.3948 13.6119 12.8135 14.2051 12.0779 14.2051C11.3423 14.2051 10.7609 13.6119 10.7609 12.8882V7.63226C10.7609 6.90853 11.3423 6.31531 12.0779 6.31531C12.8135 6.31531 13.3948 6.90853 13.3948 7.63226V12.8882Z" fill="#253746"/></g><defs><clipPath id="clip0_10636_275"><rect width="21" height="21" fill="white" transform="translate(1.57788 2.38818)"/></clipPath></defs>',
	viewBox: '0 0 25 25',
};

@Component({
	selector: 'app-data-grid',
	templateUrl: './data-grid.component.html',
	styleUrls: ['./data-grid.component.less'],
})
export class DataGridComponent implements OnInit, OnDestroy {
	@ViewChild('singleGridDataBinding')
	singleGridDataBinding: DataBindingDirective;
	@ViewChild('multiGridDataBinding')
	multiGridDataBinding: DataBindingDirective;
	public currLive = 'single';
	public currTheme = 'default';
	public currVisible = 'overview';
	public gridHeight = "100%";
	public gridData: unknown[] = products;
	public singleGridView: unknown[];
	public multiGridView: unknown[];
	public selectedRows: unknown[] = [];
	public selectAllState: SelectAllCheckboxState = 'unchecked';
	public singleSelectableSettings: SelectableSettings = {
		checkboxOnly: false,
		mode: 'single',
		drag: false,
	};
	public multiSelectableSettings: SelectableSettings = {
		checkboxOnly: false,
		mode: 'multiple',
		drag: false,
	};

	public pageSize = 25;
	public pageSizes = [10, 25, 50, 100, 200];

	// Imported Style Variables
	public stylesDefault = dataGridStylesDefaultTheme;
	public stylesDefaultCheckbox = checkboxStylesDefaultTheme;
	public stylesDefaultIcon = iconStylesDefaultTheme;
	public stylesDefaultPagination = paginationStylesDefaultTheme;
	public stylesDefaultTextLink = textLinkStylesDarkTheme;
	public stylesDark = dataGridStylesDarkTheme;
	public stylesDarkCheckbox = checkboxStylesDarkTheme;
	public stylesDarkIcon = iconStylesDarkTheme;
	public stylesDarkPagination = paginationStylesDarkTheme;
	public stylesDarkTextLink = textLinkStylesDarkTheme;
	public stylesTypography = dataGridStylesTypography;
	public stylesStructure = dataGridStylesStructure;

	public circleSlash = ShieldIcons.shCircleSlash;
	public columnsIcon = ShieldIcons.shColumns;
	public downloadIcon = ShieldIcons.shDownload;
	public filterIcon = ShieldIcons.shFilter;
	public minus = ShieldIcons.shCircleMinus;
	public searchIcon = ShieldIcons.shMagnifyingGlass;
	public settingsIcon = ShieldIcons.shGear;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Empty state',
					value: 'empty',
				},
				{
					name: 'Loading state',
					value: 'loading',
				},
				{
					name: 'Row selection',
					value: 'row-selection',
				},
				{
					name: 'Row actions',
					value: 'row-actions',
				},
				{
					name: 'Bulk actions',
					value: 'bulk-actions',
				},
				{
					name: 'Grid actions',
					value: 'grid-actions',
				},
				{
					name: 'Expand / collapse',
					value: 'expand-collapse',
				},
				{
					name: 'Item and selection counts',
					value: 'item-selection-counts',
				},
				{
					name: 'Sorting',
					value: 'sorting',
				},
				{
					name: 'Column resizing',
					value: 'column-resizing',
				},
				{
					name: 'Column reordering',
					value: 'column-reordering',
				},
				{
					name: 'Hidden columns',
					value: 'hidden-columns',
				},
				{
					name: 'Scrolling',
					value: 'scrolling',
				},
				{
					name: 'Sticky and locked columns',
					value: 'sticky-locked-columns',
				},
				{
					name: 'Pagination',
					value: 'pagination',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Single-select',
					value: 'single-select',
				},
				{
					name: 'Multi-select',
					value: 'multi-select',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
				{
					name: 'Content order',
					value: 'content-order',
				},
				{
					name: 'Cell alignment',
					value: 'cell-alignment',
				},
				{
					name: 'Data grid or data table',
					value: 'data-grid-vs-data-table',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		public changelogService: ChangelogService,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public iconSet(dataItem: any): SVGIcon {
		switch (dataItem.Connectivity) {
			case 'Failed':
				return this.circleSlash;
				break;
			case 'Warning':
				return warningDuotoneIcon;
				break;
			case 'Connector Off':
				return this.minus;
				break;
			default:
				return this.minus;
				break;
		}
	}

	public iconSet2(dataItem: any): IconThemeColor {
		switch (dataItem.Connectivity) {
			case 'Failed':
				return 'error';
				break;
			case 'Warning':
				return 'warning';
				break;
			case 'Connector Off':
				return 'info';
				break;
			default:
				return 'success';
				break;
		}
	}

	public onFilter(input: Event): void {
		const inputValue = input;
		const newData = process(this.gridData, {
			filter: {
				logic: 'or',
				filters: [
					{
						field: 'ProductName',
						operator: 'contains',
						value: inputValue,
					},
					{
						field: 'UnitsInStock',
						operator: 'contains',
						value: inputValue,
					},
					{
						field: 'UnitsOnOrder',
						operator: 'contains',
						value: inputValue,
					},
					{
						field: 'ReorderLevel',
						operator: 'contains',
						value: inputValue,
					},
				],
			},
		}).data;

		if (this.currLive === 'single') {
			this.singleGridView = newData;
			this.singleGridDataBinding.skip = 0;
		} else {
			this.multiGridView = newData;
			this.multiGridDataBinding.skip = 0;
		}
	}

	public isRowSelected = (row: RowArgs): boolean =>
		this.selectedRows.find(
			(sr) =>
				(sr as any).dataItem.ProductID ===
				(row as any).dataItem.ProductID
		) !== undefined;

	public selectionChange(event: SelectionEvent): void {
		let len;

		if (event.selectedRows.length > 0) {
			let previouslySelectedIndexes = this.selectedRows.map((row) => {
				return (row as any).dataItem.ProductID;
			});

			this.selectedRows = this.selectedRows.concat(
				event.selectedRows.filter(
					(row) =>
						!previouslySelectedIndexes.includes(
							row.dataItem.ProductID
						)
				)
			);
		}

		if (event.deselectedRows.length > 0) {
			let deselectedIndexes = event.deselectedRows.map(
				(row) => row.dataItem.ProductID
			);

			this.selectedRows = this.selectedRows.filter(
				(row) =>
					!deselectedIndexes.includes((row as any).dataItem.ProductID)
			);
		}

		len = this.selectedRows.length;

		if (len === 0) {
			this.selectAllState = 'unchecked';
		} else if (len > 0 && len < this.gridData.length) {
			this.selectAllState = 'indeterminate';
		} else {
			this.selectAllState = 'checked';
		}
	}

	public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
		if (checkedState === 'checked') {
			this.selectedRows = this.multiGridView.map((row) => {
				return { dataItem: row };
			});
			this.selectAllState = 'checked';
		} else {
			this.selectedRows = [];
			this.selectAllState = 'unchecked';
		}
	}

	public severitySet(dataItem: any): string {
		switch (dataItem.Severity) {
			case 'Critical':
				return 'sh-severity-critical';
				break;
			case 'High':
				return 'sh-severity-high';
				break;
			case 'Moderate':
				return 'sh-severity-moderate';
				break;
			case 'Low':
				return 'sh-severity-low';
				break;
			case 'None':
				return 'sh-severity-none';
				break;
			default:
				return 'none';
				break;
		}
	}

	public typeColorSet(dataItem: any): string {
		switch (dataItem.ConnectorType) {
			case 'Microsoft Azure':
				return 'sh-tag-severity--high';
				break;
			case 'Okta':
				return 'sh-tag-severity--low';
				break;
			case 'Insights Collector':
				return 'sh-tag-severity--moderate';
				break;
			default:
				return '';
				break;
		}
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public ngOnInit(): void {
		this.singleGridView = this.gridData;
		this.multiGridView = this.gridData;
	}

	public singleGridHTML: any = `
<kendo-grid
	#singleGridDataBinding
	[class.page-live-show]="currLive === 'single'"
	[kendoGridBinding]="singleGridView"
	[selectable]="singleSelectableSettings"
	[navigable]="true"
	[height]="500"
	[pageSize]="25"
	[pageable]="{ type: 'input', pageSizes: [10, 25, 50] }"
	[sortable]="true"
	[reorderable]="true"
	[resizable]="true"
	[height]="500"
	[scrollable]="'none'">
	<ng-template kendoGridToolbarTemplate>
		<div class="sh-custom-toolbar">
			<div class="sh-toolbar-actions">
				<kendo-textbox
					[style.width.px]="250"
					placeholder="Quick Filter"
					(valueChange)="onFilter($event)">
					<ng-template kendoTextBoxPrefixTemplate>
						<kendo-svg-icon [icon]="searchIcon"></kendo-svg-icon>
					</ng-template>
				</kendo-textbox>
				<kendo-button [svgIcon]="downloadIcon"></kendo-button>
				<kendo-button [svgIcon]="columnsIcon"></kendo-button>
				<kendo-button [svgIcon]="settingsIcon"></kendo-button>
			</div>
			<div class="sh-item-count-container">
				<strong class="sh-item-count">{{ gridData.length }} items</strong>
			</div>
		</div>
	</ng-template>

	<kendo-grid-checkbox-column [width]="45" [showSelectAll]="false"></kendo-grid-checkbox-column>
	<kendo-grid-column field="ProductName" title="Product Name"></kendo-grid-column>
	<kendo-grid-column field="UnitsInStock" title="Units In Stock" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="UnitsOnOrder" title="Units On Order" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="ReorderLevel" title="Reorder Level" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
</kendo-grid>`;

	public multiGridHTML: any = `
<kendo-grid #multiGridDataBinding
	[kendoGridBinding]="multiGridView" [selectable]="multiSelectableSettings" [navigable]="true"
	[height]="500" [pageSize]="pageSize" [pageable]="true" [sortable]="true" [reorderable]="true"
	[resizable]="true" [height]="500" [rowSelected]="isRowSelected"
	(selectionChange)="selectionChange($event)" [scrollable]="'none'">
	<ng-template kendoGridToolbarTemplate>
		<div class="sh-custom-toolbar">
			<div class="sh-toolbar-actions">
				<kendo-textbox [style.width.px]="250" placeholder="Quick Filter"
					[clearButton]="true"
					(valueChange)="onFilter($event)">
					<ng-template kendoTextBoxPrefixTemplate>
						<kendo-svg-icon [icon]="filterIcon"></kendo-svg-icon>
					</ng-template>
				</kendo-textbox>
				<kendo-button [svgIcon]="downloadIcon"></kendo-button>
				<kendo-button [svgIcon]="columnsIcon"></kendo-button>
				<kendo-button [svgIcon]="settingsIcon"></kendo-button>
			</div>
			<div class="sh-item-count-container">
				<strong class="sh-item-count">{{ multiGridView.length }} items</strong>
				{{ '(' }}{{ selectedRows.length }} selected |
				<a class="sh-item-count-selection" tabindex="0" aria-disabled="false"
					aria-haspopup="false" type="button" (click)="
						onSelectAllChange(
							selectAllState === 'checked'
								? 'unchecked'
								: 'checked'
						)
					">{{ selectedRows.length === multiGridView.length
						? 'Deselect All'
						: 'Select all ' + multiGridView.length + ' rows' }}
				</a>{{ ')' }}
			</div>
		</div>
	</ng-template>
	<kendo-grid-checkbox-column [width]="45">
		<ng-template kendoGridHeaderTemplate>
			<input #selectAllCheckbox type="checkbox" kendoCheckBox id="selectAllCheckbox"
				kendoGridSelectAllCheckbox [state]="selectAllState"
				(selectAllChange)="onSelectAllChange($event)" />
			<label class="k-checkbox-label" for="selectAllCheckbox"></label>
		</ng-template>
	</kendo-grid-checkbox-column>
	<kendo-grid-column field="ProductName" title="Product Name"></kendo-grid-column>
	<kendo-grid-column field="UnitsInStock" title="Units In Stock" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="UnitsOnOrder" title="Units On Order" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="ReorderLevel" title="Reorder Level" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
		<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
		<kendo-pager-input></kendo-pager-input>
		<kendo-pager-next-buttons></kendo-pager-next-buttons>
		<kendo-pager-info></kendo-pager-info>
		<kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
	</ng-template>
</kendo-grid>`;
}

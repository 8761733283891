<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Tooltip'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">Default</option>
					<option value="chart-tooltip">Chart tooltip</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example page-live-tooltip" [class.page-live-show]="currLive === 'default'">
					<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-top"></kendo-button>
					<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-right"></kendo-button>
					<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-bottom"></kendo-button>
					<kendo-button [svgIcon]="questionMark" ToolTip="Help" class="tooltip-left"></kendo-button>
				</div>
				<div class="page-live-example" [class.page-live-show]="currLive === 'chart-tooltip'">
					<kendo-chart [transitions]="true" [pannable]="false" [zoomable]="false"
						[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }" [chartArea]="{height: 400, width: 600}">
						<kendo-chart-value-axis>
							<kendo-chart-value-axis-item *ngFor="let item of valueAxes" [name]="item.name"
								[title]="item.title"> </kendo-chart-value-axis-item>
						</kendo-chart-value-axis>
						<kendo-chart-category-axis>
							<kendo-chart-category-axis-item [labels]="labelOptions"> </kendo-chart-category-axis-item>
						</kendo-chart-category-axis>
						<kendo-chart-legend position="right">
							<kendo-chart-legend-item [visual]="labelsVisual" cursor="pointer">
							</kendo-chart-legend-item>
						</kendo-chart-legend>
						<kendo-chart-series>
							<kendo-chart-series-item [opacity]="0.5" *ngFor="let groupedResult of groupedData"
								[data]="groupedResult.items" [name]="groupedResult.value" field="data"
								categoryField="categoryName" noteTextField="labelName" colorField="colorString"
								[notes]="notes" [type]="selectedBarType" [stack]="stackFlag">
								<ng-container *ngIf="!stackFlag">
									<kendo-chart-series-item-labels [content]="labelVisual"
										[background]="'transparent'"> </kendo-chart-series-item-labels>
								</ng-container>
								<kendo-chart-series-item-tooltip>
									<ng-template let-category="category" let-value="value" let-series="series">
										<div class="toolTip">
											<div>{{ category }}</div>
											<div>{{ series.name }}</div>
											<div>
												{{ value }}
											</div>
										</div>
									</ng-template>
								</kendo-chart-series-item-tooltip>
							</kendo-chart-series-item>
						</kendo-chart-series>
					</kendo-chart>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			Added to your project's app.module.ts file:
			<code
				style="margin-top: 10px">import &#123; TooltipsModule &#125; from '&#64;progress/kendo-angular-tooltip';</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<pre prism class="dark" [hidden]="currLive !== 'default'">{{ defaultTooltipHTML }}</pre>
			<pre prism class="dark" [hidden]="currLive !== 'chart-tooltip'">{{ chartTooltipHTML }}</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/tooltips/api/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Tooltip API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/tooltip#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-show-hide">
			<h3 id="subSectionTitleBehaviorsShowHide" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-show-hide')">
				Show/hide <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Tooltips appear when hovering over, or focusing on the element they're related to. When the user
					moves away from the
					element, the tooltip disappears.
				</li>
				<li class="page-section-list-item">
					When using keyboard navigation, refer to the <a
						href="components/tooltip#usage-accessibility">Accessibility</a> section for guidance.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-animation">
			<h3 id="subSectionTitleBehaviorsAnimation" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-animation')">
				Animation <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Tooltips appear with an animation lasting 250ms, as mentioned in the <a
						href="guidelines/motion">motion guidelines</a>.
					Tooltips disappear at the speed set by Kendo.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-default">
			<h3 id="subSectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-default')">
				Default <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Default tooltips appear near an element being hovered over or focused on. Default tooltips contain
					only text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-chart-tooltips">
			<h3 id="subSectionTitleVariationsChartTooltips" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('variations-chart-tooltips')">
				Chart tooltips <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Chart tooltips only appear when a chart element is hovered over.
				</li>
				<li class="page-section-list-item">
					Chart tooltips contain any necessary information for understanding the chart element being
					referenced. This can include
					color blocks or other details like percentages. These tooltips are intended to be concise.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section">
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do use tooltips:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							To give users background information about the action they’re performing
						</li>
						<li class="page-section-list-item">
							For “nice-to-know” information that can help new users but experienced users can bypass
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don't use tooltips:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							To hide critical instructions that are necessary to a user’s success
						</li>
						<li class="page-section-list-item">
							As a substitute for an intuitive UI design with clear, purposeful labels
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The content in a tooltip should be supplemental—not required—information.
					Tooltips also shouldn’t just repeat what is already visible on the UI, like
					an element’s label. Think of tooltips as giving the user extra context about
					something. It could be a definition, or the “why” behind what a user is
					doing.
				</li>
				<li class="page-section-list-item">
					Tooltips should be concise, no more than a sentence or two. Tooltips should be written in sentence
					case.
				</li>
				<li class="page-section-list-item">
					Examples of informational content in a tooltip:
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-bulleted-list">
						<li class="page-section-list-item">
							What’s this and what does it do?
						</li>
						<li class="page-section-list-item">
							Where can I find this?
						</li>
						<li class="page-section-list-item">
							Why do I have to do this, or why should I do this?
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
									<title>Icon/Status/checkmark</title>
									<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
										fill-rule="evenodd">
										<path
											d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
											id="" fill="#000000" />
									</g>
								</svg>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										You can find this information in your Password Safe Cloud settings.
									</li>
									<li class="comparison-body-text-list-item">
										We’re asking for this so we can better understand how you use our products.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
									<title>Icon/NavObjects/close</title>
									<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
										fill-rule="evenodd">
										<path
											d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
											id="add2" fill="#000000"
											transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
									</g>
								</svg>
								Not This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										To finish setting up this connector, you need to enter this information.
									</li>
									<li class="comparison-body-text-list-item">
										In order to continue with the survey, we need this information.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-accessibility">
			<h3 id="subSectionTitleUsageAccessibility" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-accessibility')">
				Accessibility <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Tooltips should work on both hover and focus. Hover states often don’t work well on mobile devices
					and screen readers,
					so tooltips that also activate on focus cover a broader range of users.
				</li>
				<li class="page-section-list-item">
					When using keyboard navigation, the tooltip will appear on focus and remain visible until the focus
					is moved to another
					element.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-placement">
			<h3 id="subSectionTitleUsagePlacement" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-placement')">
				Placement <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When possible, tooltips appear centered above the element they're tied to. Ideally, tooltips
					shouldn’t cover clickable or interactive elements. They also shouldn't be cut off by the end of
					the browser window in any direction. In these situations, move the tooltip to the nearest place
					that doesn't cover important elements or get cut short.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-tooltip-or-help-text">
			<h3 id="subSectionTitleUsageTooltipVsHelpText" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-tooltip-vs-help-text')">
				Tooltip or help text <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Tooltips:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							For shorter informational content, like quick definitions and short explanations
						</li>
						<li class="page-section-list-item">
							Displayed on either focus or hover of an element
						</li>
						<li class="page-section-list-item">
							Content is text only with little to no formatting
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Help text:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							For longer informational content, like detailed instructions
						</li>
						<li class="page-section-list-item">
							Displayed by selecting an icon, often a question mark
						</li>
						<li class="page-section-list-item">
							Can contain different formats of content, including images
						</li>
					</ul>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="guidelines/accessibility">Accessibility guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/help-text">Help text component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/motion">Motion guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Tooltip Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
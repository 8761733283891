import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
	selector: 'app-theme-switcher',
	templateUrl: './theme-switcher.component.html',
	styleUrls: ['./theme-switcher.component.less'],
})
export class ThemeSwitcherComponent {
	@ViewChild('themeSwitcher', { static: false })
	themeSwitcher: ElementRef<HTMLInputElement>;

	onThemeSwitch(evt: any) {
		let themeValue = evt.target.value;
		document.documentElement.setAttribute('data-theme', themeValue);
	}
}

import { Component, OnInit, Input } from '@angular/core';
import { StyleRow } from './style-row.interface';

@Component({
	selector: 'app-style-row',
	templateUrl: './style-row.component.html',
	styleUrls: ['./style-row.component.less'],
})
export class StyleRowComponent implements OnInit {
	@Input() data: StyleRow;

	constructor() {}

	ngOnInit(): void {}
}

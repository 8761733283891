// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const menuStylesDefaultTheme = [
	{
		name: 'Background',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text/ Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Separator',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const menuStylesDarkTheme = [
	{
		name: 'Background',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Text/ Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Separator',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const menuStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const menuStylesStructure = [
	{
		name: 'Container',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '240px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '400px',
		isColorTile: false,
	},
	{
		name: 'Separator',
		property: 'height',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Menu Item',
		property: 'height',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Menu Item Icon',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];

import { Component, OnInit, ViewChild } from '@angular/core';
import { searchData } from './search-data';
import { Router } from '@angular/router';
import { shMagnifyingGlass } from '@beyondtrust/shield-icons';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.less'],
})
export class SearchComponent implements OnInit {
	@ViewChild('criteria', { static: false }) criteria: any;
	@ViewChild('SearchInput', { static: false }) searchInput: AutoCompleteComponent;
	//@ViewChild("SearchInput", { static: true }) public searchInput: any;

	public inputDropDownShown = false;
	public data = searchData;
	public inputValue = '';
	public seachResults: any[] = [];

	//public seachResults = this.data.filter((searchFilter) =>
	//	searchFilter.meta
	//		.toLocaleLowerCase()
	//		.includes('data')
	//);

	public searchIcon = shMagnifyingGlass;

	public highlightFirst = false;

	constructor(private router: Router) {
	}

	ngOnInit(): void {
		//this.searchInput.toggle(true);
	}

	public handleFilter(value: any) {
		this.inputDropDownShown = true;
		let criteria = value;

		if (criteria.length > 2) {
			this.seachResults = this.data.filter((searchFilter) =>
				searchFilter.meta
					.toLocaleLowerCase()
					.includes(criteria.toLocaleLowerCase())
			);
		} else {
			this.seachResults = [];
		}

		if (criteria.length === 0) {
			this.inputDropDownShown = false;
		}
	}

	public handleValueChange(value: any) {
		this.redirectTo(value);
	}

	public redirectTo(uri: any) {
		this.searchInput.value = '';
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}
}

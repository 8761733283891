// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: tooltipStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const tooltipStylesDefaultTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];
export const tooltipStylesDarkTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
];
export const tooltipStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];
export const tooltipStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '500px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
];

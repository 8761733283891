import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	menuStylesDefaultTheme,
	menuStylesDarkTheme,
	menuStylesTypography,
	menuStylesStructure,
} from './menu-styles';
import {
	shCirclePlus,
	shPerson,
	shActiveDirectory,
	shLdap,
	shSailpoint,
	shCheck,
	shXmark,
	shDotsVertical,
	shEye,
	shCircleInfo,
	shFolderCircleEye,
	shEdit,
} from '@beyondtrust/shield-icons';
import {
	buttonStylesDarkThemeIcon,
	buttonStylesDarkThemeTextIcon,
	buttonStylesDefaultThemeIcon,
	buttonStylesDefaultThemeTextIcon,
	buttonStylesTypography,
} from '../button/button-styles';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.less'],
})
export class MenuComponent implements OnDestroy {
	public currLive = 'button-trigger';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = menuStylesDefaultTheme;
	public stylesDefaultTextButton = buttonStylesDefaultThemeTextIcon;
	public stylesDefaultIconButton = buttonStylesDefaultThemeIcon;
	public stylesDark = menuStylesDarkTheme;
	public stylesDarkTextButton = buttonStylesDarkThemeTextIcon;
	public stylesDarkIconButton = buttonStylesDarkThemeIcon;
	public stylesTypography = menuStylesTypography;
	public stylesTypographyTrigger = buttonStylesTypography;
	public stylesStructure = menuStylesStructure;

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;

	public buttonTriggerMenu = {
		text: 'Create Group',
		icon: shCirclePlus,
		items: [
			{ text: 'Create User Group', icon: shPerson },
			{ text: 'Add Active Directory Group', icon: shActiveDirectory },
			{ text: 'Add LDAP Group', icon: shLdap },
			{ text: 'Add SailPoint Role', icon: shSailpoint },
		],
	};

	public iconButtonTriggerMenu = {
		text: '',
		icon: shDotsVertical,
		items: [
			{ text: 'View Policy', icon: shEye },
			{ text: 'Policy Details', icon: shCircleInfo },
			{ text: 'Revision history', icon: shFolderCircleEye },
			{ text: 'separator' },
			{ text: 'Edit Policy', icon: shEdit, disabled: true },
		],
	};

	public menuWithSeparator = {
		text: 'Create Group',
		icon: shCirclePlus,
		items: [
			{ text: 'Create User Group', icon: shPerson },
			{ text: 'separator' },
			{ text: 'Add Active Directory Group', icon: shActiveDirectory },
			{ text: 'Add LDAP Group', icon: shLdap },
			{ text: 'Add SailPoint Role', icon: shSailpoint },
		],
	};

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
				{
					name: 'Scrollbars',
					value: 'scrollbars',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: true,
			children: [],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
				{
					name: 'Dimensions',
					value: 'list-dimensions',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public buttonTriggerMenuHTML: any = `
<kendo-menu
	[openOnClick]="{ toggle: 'click' }"
	(close)="onClose($event)"
>
	<ng-template
		kendoMenuItemLinkTemplate
		let-item="item"
		let-index="index"
	>
		<kendo-button
			themeColor="primary"
			fillMode="flat"
			[kendoMenuItemLink]="index"
			[svgIcon]="item.svgIcon"
		>
			{{ item.text }}
			<span
				*ngIf="item.items && item.items.length"
				[kendoMenuExpandArrow]="index"
			></span>
		</kendo-button>
	</ng-template>
	<kendo-menu-item
		[text]="buttonTriggerMenu.text"
		[svgIcon]="buttonTriggerMenu.icon"
	>
		<kendo-menu-item
			*ngFor="let subitem of buttonTriggerMenu.items"
			[text]="subitem.text"
			[svgIcon]="subitem.icon"
		>
		</kendo-menu-item>
	</kendo-menu-item>
</kendo-menu>`;

	public iconButtonTriggerMenuHTML: any = `

<kendo-menu
	[openOnClick]="{ toggle: 'click' }"
	(close)="onClose($event)"
>
	<ng-template
		kendoMenuItemLinkTemplate
		let-item="item"
		let-index="index"
	>
		<kendo-button
			themeColor="primary"
			fillMode="flat"
			[kendoMenuItemLink]="index"
			[svgIcon]="item.svgIcon"
		>
			{{ item.text }}
		</kendo-button>
	</ng-template>
	<kendo-menu-item
		[text]="iconButtonTriggerMenu.text"
		[svgIcon]="iconButtonTriggerMenu.icon"
	>
		<kendo-menu-item
			*ngFor="let subitem of iconButtonTriggerMenu.items"
			[text]="subitem.text"
			[svgIcon]="subitem.icon"
		>
		</kendo-menu-item>
	</kendo-menu-item>
</kendo-menu>`;

	public menuWithSeparatorHTML: any = `
<kendo-menu
	[openOnClick]="{ toggle: 'click' }"
	(close)="onClose($event)"
>
	<ng-template
		kendoMenuItemLinkTemplate
		let-item="item"
		let-index="index"
	>
		<kendo-button
			themeColor="primary"
			fillMode="flat"
			[kendoMenuItemLink]="index"
			[svgIcon]="item.svgIcon"
		>
			{{ item.text }}
			<span
				*ngIf="item.items && item.items.length"
				[kendoMenuExpandArrow]="index"
			></span>
		</kendo-button>
	</ng-template>
	<kendo-menu-item
		[text]="menuWithSeparator.text"
		[svgIcon]="menuWithSeparator.icon"
	>
		<kendo-menu-item
			*ngFor="let subitem of menuWithSeparator.items"
			[text]="subitem.text"
			[svgIcon]="subitem.icon"
			[separator]="subitem.text === 'separator'"
		>
		</kendo-menu-item>
	</kendo-menu-item>
</kendo-menu>`;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public onClose(e: any): void {
		if (e.item.contentTemplate) {
			e.preventDefault();
		}
	}
}

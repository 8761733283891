import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { SVGIcon, shCircleQuestion } from '@beyondtrust/shield-icons';
import { AnimationDirection, AnimationType } from '@progress/kendo-angular-popup';
import { PopoverAnimation } from '@progress/kendo-angular-tooltip';

// Data Sources
import {
	helpTextStylesDefaultTheme,
	helpTextStylesDarkTheme,
	helpTextStylesTypography,
	helpTextStylesStructure,
} from './help-text-styles';

@Component({
	selector: 'app-help-text',
	templateUrl: './help-text.component.html',
	styleUrls: ['./help-text.component.less'],
})
export class HelpTextComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = helpTextStylesDefaultTheme;
	public stylesDark = helpTextStylesDarkTheme;
	public stylesTypography = helpTextStylesTypography;
	public stylesStructure = helpTextStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started'
				},
				{
					name: 'Code examples',
					value: 'code'
				},
				{
					name: 'API',
					value: 'api'
				}
			]
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus state',
					value: 'focus',
				},
				{
					name: 'Animation',
					value: 'animation',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: true,
			children: []
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
				{
					name: 'Help text or tooltip',
					value: 'help-text-or-tooltip',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];
	public circleQuestion: SVGIcon = shCircleQuestion;

	public type: AnimationType = 'slide';
	public direction: AnimationDirection = 'right';
	public duration = 250;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public get myAnimation(): PopoverAnimation {
		return {
		  type: this.type,
		  duration: this.duration,
		  direction: this.direction
		};
	}

	public helpTextHTML = `
<button kendoButton [svgIcon]="circleQuestion" kendoTooltip title="Help" kendoPopoverAnchor [popover]="myPopover"></button>
<kendo-popover
	#myPopover
	[animation]="myAnimation"
	[width]="200"
	title="Credentials"
	body="You must select a managed system first before associating a credential."
></kendo-popover>
	`;

	public helpTextTs = `
public type: AnimationType = 'slide';
public direction: AnimationDirection = 'right';
public duration = 250;
public get myAnimation(): PopoverAnimation {
	return {
	  type: this.type,
	  duration: this.duration,
	  direction: this.direction
	};
}
	`
}

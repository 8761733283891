import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

enum DataTypes {
	default = 'default',
	severity = 'severity',
	status = 'status',
}

import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { LegendItemVisualArgs } from '@progress/kendo-angular-charts';
import {
	Element,
	geometry,
	Group,
	Path,
	Rect as RectShape,
	Text,
} from '@progress/kendo-drawing';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

// import { ChartOptions } from 'chart.js';
import {
	donutChartStylesDefaultTheme,
	donutChartStylesDarkTheme,
	donutChartStylesTypography,
	donutChartStylesStructure,
	donutChartStylesDefaultThemeSeverity,
	donutChartStylesDefaultThemeStatus,
	donutChartStylesDarkThemeSeverity,
	donutChartStylesDarkThemeStatus,
} from './donut-chart-styles';

const { Point, Rect, Size } = geometry;

@Component({
	selector: 'app-donut-chart',
	templateUrl: './donut-chart.component.html',
	styleUrls: ['./donut-chart.component.less'],
})
export class DonutChartComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public doThisIcon = shCheck;
	public notThisIcon = shXmark;

	// Imported Style Variables
	public stylesDefault = donutChartStylesDefaultTheme;
	public stylesDefaultSeverity = donutChartStylesDefaultThemeSeverity;
	public stylesDefaultStatus = donutChartStylesDefaultThemeStatus;
	public stylesDark = donutChartStylesDarkTheme;
	public stylesDarkSeverity = donutChartStylesDarkThemeSeverity;
	public stylesDarkStatus = donutChartStylesDarkThemeStatus;
	public stylesTypography = donutChartStylesTypography;
	public stylesStructure = donutChartStylesStructure;
	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Legend visibility',
					value: 'legend',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Severity',
					value: 'severity',
				},
				{
					name: 'Status',
					value: 'status',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general-use',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public autofit = true;
	public series: any[] = [];
	public dataTypes = DataTypes;
	public selectedDataType = this.dataTypes.default;

	public defaultLightSeries = [
		{
			kind: 'Data-01',
			share: 300,
			colorString: '#6699FF',
		},
		{
			kind: 'Data-02',
			share: 200,
			colorString: '#FC814C',
		},
		{
			kind: 'Data-03',
			share: 150,
			colorString: '#99F99F',
		},
		{
			kind: 'Data-04',
			share: 100,
			colorString: '#CC99FF',
		},
		{
			kind: 'Data-05',
			share: 1770,
			colorString: '#FFD35B',
		},
		{
			kind: 'Data-06',
			share: 1140,
			colorString: '#FF551E',
		},
	];
	public defaultDarkSeries = [
		{
			kind: 'Data-01',
			share: 300,
			colorString: '#6699FF',
		},
		{
			kind: 'Data-02',
			share: 200,
			colorString: '#FC814C',
		},
		{
			kind: 'Data-03',
			share: 150,
			colorString: '#99F99F',
		},
		{
			kind: 'Data-04',
			share: 100,
			colorString: '#CC99FF',
		},
		{
			kind: 'Data-05',
			share: 1770,
			colorString: '#FFD35B',
		},
		{
			kind: 'Data-06',
			share: 1140,
			colorString: '#FF551E',
		},
	];

	public severityLightSeries = [
		{
			kind: 'Critical',
			share: 100,
			colorString: '#783A3B',
		},
		{
			kind: 'High',
			share: 1500,
			colorString: '#C67839',
		},
		{
			kind: 'Moderate',
			share: 200,
			colorString: '#C6b539',
		},
		{
			kind: 'Low',
			share: 300,
			colorString: '#6699CC',
		},
		{
			kind: 'Informational',
			share: 75,
			colorString: '#007D9A',
		},
	];
	public severityDarkSeries = [
		{
			kind: 'Critical',
			share: 100,
			colorString: '#D6B9FF',
		},
		{
			kind: 'High',
			share: 1500,
			colorString: '#F39681',
		},
		{
			kind: 'Moderate',
			share: 200,
			colorString: '#F3C781',
		},
		{
			kind: 'Low',
			share: 300,
			colorString: '#71CFE6',
		},
		{
			kind: 'Informational',
			share: 75,
			colorString: '#6699CC',
		},
	];

	public statusLightSeries = [
		{
			kind: 'Success',
			share: 450,
			colorString: '#006E18',
		},
		{
			kind: 'Warning',
			share: 350,
			colorString: '#FFE900',
		},
		{
			kind: 'Error',
			share: 100,
			colorString: '#C1001B',
		},
		{
			kind: 'Informational',
			share: 100,
			colorString: '#007D9A',
		},
	];
	public statusDarkSeries = [
		{
			kind: 'Success',
			share: 450,
			colorString: '#7FC5A2',
		},
		{
			kind: 'Warning',
			share: 350,
			colorString: '#F6C664',
		},
		{
			kind: 'Error',
			share: 100,
			colorString: '#FF8593',
		},
		{
			kind: 'Informational',
			share: 100,
			colorString: '#6699CC',
		},
	];
	public donutChartHTML: any = `
<kendo-chart [transitions]="true">
	<kendo-chart-legend position="right">
		<kendo-chart-legend-item 
			[visual]="legendVisual" 
			cursor="pointer">
		</kendo-chart-legend-item>
	</kendo-chart-legend>
	<kendo-chart-series>
		<kendo-chart-series-item 
			[opacity]="0.5" 
			[autoFit]="autofit" 
			type="donut" 
			[data]="series"
			categoryField="name" 
			field="value" 
			[colorField]="'hex'">
			<kendo-chart-series-item-labels 
				position="outsideEnd" 
				color="#d0d3d4" 
				[content]="labelContent">
			</kendo-chart-series-item-labels>
		</kendo-chart-series-item>
	</kendo-chart-series>
</kendo-chart>`;
	public legendVisualTs: any = `
public legendVisual(args: LegendItemVisualArgs): Element {
	const pathColor = args.options.markers.border.color;
	const rectOptions = {
		stroke: {
			color: pathColor,
			width: 1
		},
		fill: {
			color: pathColor,
			opacity: 0.5
		}
	};
	const rectGeometry = new Rect(new Point(0, 0), new Size(25, 10));
	const rect: RectShape = new RectShape(rectGeometry, rectOptions);
	
	const lineThrough = new Path({
	stroke: {
		color: args.options.labels.color,
		width: 3
	}
	});

	const labelText1 = args.series.data[args.pointIndex].value;
	const labelText2 = args.series.data[args.pointIndex].name;

	const labelFont = args.options.labels.font;
	const fontColor = args.options.labels.color;
	const textOptions = {
		font: labelFont,
		fill: { color: fontColor }
	};

	const text1 = new Text(labelText1, new Point(30, -4), textOptions);
	const text2 = new Text(labelText2, new Point(80, -4), textOptions);

	const textBox1 = text1.bbox();
	const textBox2 = text2.bbox();
	const labelLength = textBox1.size.width + textBox2.size.width + 53;
	
	const group = new Group();
	group.append(rect, text1, text2);
	if (!args.active) {
		lineThrough.moveTo(30, 5).lineTo(labelLength, 5).close();
		group.append(rect, text1, lineThrough, text2);
		group.opacity(0.5);
	}
	return group;
}`;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
		this.labelContent = this.labelContent.bind(this);
		this.changePalette();
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
		switch (this.currLive) {
			case 'severity':
				this.changePalette('dataSeverity');
				break;
			case 'status':
				this.changePalette('dataStatus');
				break;
			default:
				this.changePalette();
				break;
		}
	}

	// public changeCurrTheme(event): void {
	// 	this.currTheme = event.target.value;
	// }

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public labelContent(e: SeriesLabelsContentArgs): string {
		return `${e.value} ${e.category}`;
	}

	public legendVisual(args: LegendItemVisualArgs): Element {
		const pathColor = args.options.markers.border.color;
		const rectOptions = {
			stroke: {
				color: pathColor,
				width: 1,
			},
			fill: {
				color: pathColor,
				opacity: 0.5,
			},
		};
		const rectGeometry = new Rect(new Point(0, 0), new Size(25, 10));
		const rect: RectShape = new RectShape(rectGeometry, rectOptions);
		const lineThrough = new Path({
			stroke: {
				color: args.options.labels.color,
				width: 3,
			},
		});
		const labelText1 = args.series.data[args.pointIndex].share;
		const labelText2 = args.series.data[args.pointIndex].kind;
		const labelFont = args.options.labels.font;
		const fontColor = args.options.labels.color;
		const textOptions = {
			font: labelFont,
			fill: { color: fontColor },
		};
		const text1 = new Text(labelText1, new Point(30, -4), textOptions);
		const text2 = new Text(labelText2, new Point(80, -4), textOptions);
		const textBox1 = text1.bbox();
		const textBox2 = text2.bbox();
		const labelLength = textBox1.size.width + textBox2.size.width + 53;
		const group = new Group();
		group.append(rect, text1, text2);
		if (!args.active) {
			lineThrough.moveTo(30, 5).lineTo(labelLength, 5).close();
			group.append(rect, text1, lineThrough, text2);
			group.opacity(0.5);
		}
		return group;
	}

	public changePalette(str?: string): void {
		switch (str) {
			case 'dataStatus': {
				this.selectedDataType = this.dataTypes.status;
				break;
			}
			case 'dataSeverity': {
				this.selectedDataType = this.dataTypes.severity;
				break;
			}
			default: {
				this.selectedDataType = this.dataTypes.default;
				break;
			}
		}
	}

	public onLegendItemClick(evt: any): void {
		console.log('Legend Item Click: ' + evt.text);
	}

	public onSeriesClick(evt: any): void {
		console.log(
			'Series Click: ' +
				evt.dataItem.name +
				' ' +
				evt.dataItem.value +
				' ' +
				evt.dataItem.hex
		);
	}
}

import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

// Data Sources
import {
	checkboxStylesDefaultTheme,
	checkboxStylesDarkTheme,
	checkboxStylesTypographyAll,
	checkboxStylesTypographySupporting,
	checkboxStylesStructureAll,
	checkboxStylesStructureSupporting,
} from './checkbox-styles';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCircleQuestion } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.less'],
})
export class CheckboxComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public values: boolean[] = [false, false, false, false, false];
	public indeterminate = false;
	public questionCircleIcon = shCircleQuestion;
	// Imported Style Variables
	public stylesDefault = checkboxStylesDefaultTheme;
	public stylesDark = checkboxStylesDarkTheme;
	public stylesTypographyAll = checkboxStylesTypographyAll;
	public stylesTypographySupporting = checkboxStylesTypographySupporting;
	public stylesStructureAll = checkboxStylesStructureAll;
	public stylesStructureSupporting = checkboxStylesStructureSupporting;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				// {
				// 	name: 'Getting started',
				// 	value: 'started',
				// },
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Selected',
					value: 'selected',
				},
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
				{
					name: 'Indeterminate (parent/child)',
					value: 'indeterminate',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Supporting content',
					value: 'supporting-content',
				},
				{
					name: 'Tooltip',
					value: 'tooltip',
				},
				{
					name: 'Single option',
					value: 'single-option',
				},
				{
					name: 'Multiple options',
					value: 'multiple-options',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Positioning',
					value: 'positioning',
				},
				{
					name: 'Parent/child indentation',
					value: 'parent-child-indentation',
				},
				{
					name: 'Accessibility',
					value: 'accessibility',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public toggleAll() {
		this.values[5] = this.values[6] = this.values[7] = this.values[4];
		this.indeterminate = false;
	}

	public indeterminateChange() {
		const allFalse = this.values.slice(5, 8).every((v) => !v);
		this.values[4] = this.values.slice(5, 8).every((v) => v);
		if (allFalse) {
			this.indeterminate = false;
		} else {
			this.indeterminate = !(
				this.values[5] &&
				this.values[6] &&
				this.values[7]
			);
		}
	}

	public checkboxHTML = `
<kendo-formfield>
	<div class="k-checkbox-wrap">
		<input #checkboxInput type="checkbox" kendoCheckBox name="checkbox-input" [(ngModel)]="value">
		<kendo-label [for]="checkboxInput" text="A simple checkbox"></kendo-label>
	</div>
</kendo-formfield>`;
}

<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>

	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Text Field'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default</option>
					<option value="multi-line">Multi-line</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<form class="k-form" [class.page-live-show]="currLive === 'default'" [formGroup]="form">
					<kendo-formfield showHints="always">
						<kendo-label [for]="firstName" text="First Name (Required)"></kendo-label>
						<kendo-textbox formControlName="firstName" #firstName required [style.width.px]="400"
							[maxlength]="255">
							<ng-template kendoTextBoxSuffixTemplate>
								<button kendoTooltip *ngIf="firstName.value && firstName.value.length > 0" kendoButton
									title="Clear First Name" class="suffix-clear-button" [svgIcon]="xMarkIcon" (click)="firstName.clearValue();"></button>
							</ng-template>
						</kendo-textbox>

						<kendo-formhint>Your first name.</kendo-formhint>
						<kendo-formerror>First name is required. Enter a name.</kendo-formerror>
					</kendo-formfield>
				</form>
				<div class="k-form" [class.page-live-show]="currLive === 'multi-line'">
					<kendo-label class="k-form-field" text="Description">
						<kendo-textarea #textArea [style.width.px]="400" [rows]="3" resizable="none" [maxlength]="300"
							[(value)]="textareaValue">
							<kendo-textarea-suffix>
								<button kendoTooltip *ngIf="textareaValue.length > 0" kendoButton
									title="Clear Description" class="suffix-clear-button" [svgIcon]="xMarkIcon" (click)="textareaValue = '';textArea.blur();"></button>
							</kendo-textarea-suffix>
						</kendo-textarea>
					</kendo-label>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="sectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			Added to your project's app.module.ts file:
			<code style="margin-top: 10px">import &#123; InputsModule &#125; from
				'&#64;progress/kendo-angular-inputs';
			</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="sectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark page-pre-code" style="max-width: unset;" [hidden]="currLive !== 'default'">
				{{ defaultTextFieldHTML }}
			</pre>
			<pre prism class="dark page-pre-code" style="max-width: unset;" [hidden]="currLive !== 'multi-line'">
				{{ multiLineTextFieldHTML }}
			</pre>
		</section>
		<!-- <section class="page-sub-section" id="dev-api">
			<h3
				id="sectionTitleDevApi"
				class="page-sub-section-title"
				title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')"
			>
				API
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
				</li>
			</ul>
		</section> -->
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="sectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A dashed focus state replaces the browser default focus
					state for text fields. The border also changes color on
					focus.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-clear">
			<h3 id="sectionTitleBehaviorsClear" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-clear')">
				Clearing text
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A clear icon appears in the right side of the text field if
					one or more characters has been entered. Selecting the clear
					icon resets the text field back to its default. It removes
					the text values and resets any character counts shown. The
					clear icon has a <a href="components/tooltip">tooltip</a> of "clear" added to the label of
					the text field. If the field label is <b>System Name</b>,
					the tooltip would be <b>Clear system name</b>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-error">
			<h3 id="sectionTitleBehaviorsError" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-error')">
				Error
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Error states in a text field change the color of the border
					and display an error message below the field. There are
					different reasons for a text field error state. Required
					fields and formatting conditions, (e.g., when a user needs
					to enter a certain number of characters), are two examples.
				</li>
				<li class="page-section-list-item">
					With formatting errors, the text field border returns to
					normal and the error message is immediately removed once the
					user addresses the error. Required fields errors are only
					resolved once the user has fixed the issue and moved on to
					another area of the screen.
				</li>
				<li class="page-section-list-item">
					It’s possible for both an error and instructional text to display at the same time.
					When this happens, the error message appears below the instructional text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disabled">
			<h3 id="sectionTitleBehaviorsDisabled" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disabled')">
				Disabled
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When disabled, the field is shown with a darkened background with 50% opacity. The field can’t be
					selected, focused, or interacted with in any way. A disabled field should be shown unfilled if the
					field was originally empty.
				</li>
				<li class="page-section-list-item">
					Text with 50%
					opacity would be too hard to read, so if a disabled text field needs pre-filled content, use a
					<a href="components/label-value">label/value pair</a> instead.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-help-text">
			<h3 id="sectionTitleBehaviorsHelpText" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-help-text')">
				Help text
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text field labels can give extra context or instructions
					using a <a href="components/help-text">help text component</a>. This appears as a question mark
					<a href="components/icon">icon</a> to the right of the label.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-instructional-text">
			<h3 id="subSectionTitleBehaviorsInstructionalText" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('behaviors-instructional-text')">
				Instructional text
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Instructional text appears below the field. It shouldn't be
					italicized as italic font isn't readable. It shouldn't
					extend past the text field and only be used for very short
					messages. If more instruction is needed, use <a href="components/help-text">help text</a>. For
					more guidance on how to write instructional text,
					<a href="components/text-field#usage-content">go to the content section below</a>.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/inputs/formfield/hints-errors/">
						<svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>
						Kendo Hints and Errors
					</a>
				</li>
				<li class="page-section-list-item">
					It’s possible for both an error and instructional text to display at the same time.
					When this happens, the error message appears below the instructional text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-character-limit">
			<h3 id="subSectionTitleBehaviorsCharacterLimit" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('behaviors-character-limit')">
				Character limits
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					All text fields should have a maximum character limit. The
					default is 255 characters, but it should be chosen with the
					field context and database restrictions in mind.
				</li>
				<li class="page-section-list-item">
					The character limit appears to the right of the text field
					if at least 80% of the character limit has been reached. The
					limit lets the user know how many characters are left. If
					the user goes past the limit, the field shows an error state
					until the user removes characters.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-default">
			<h3 id="sectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-default')">
				Default
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A form input field that only allows one line of text to be
					entered. If a user is entering only a few words or a brief
					sentence, a default, single-line text field is recommended.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-multiple">
			<h3 id="sectionTitleVariationsMultiple" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-multiple')">
				Multi-line
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A text area that allows multiple lines of text. As a
					multi-line text field expands to fill the content entered,
					it pushes down form elements below it. On page load, a
					multi-line text field is expanded enough to show all content
					entered.
				</li>
				<li class="page-section-list-item">
					If you expect a user will need to enter a lot of text
					(multiple sentences) into a text field, use the multi-line
					variation.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-optional-required">
			<h3 id="subSectionVariationsOptionalRequired" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-optional-required')">
				Optional and required
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Required fields should be labeled (Required), Optional fields can be tagged (Optional). You can
					choose to only label the
					fields that are in the minority. For example, if there are only 2 optional out of 10 entries, only
					the optional ones
					would be tagged.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-typeahead">
			<h3 id="subSectionVariationsTypeahead" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-typeahead')">
				Typeahead
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text fields can suggest options to users as they type. This
					is known as typeahead or autocomplete. The options can
					either be presented inline or as a drop-down. Use drop-down,
					unless there are only a few options and space is limited.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-min-max-width">
			<h3 id="sectionTitleUsageMinMaxWidth" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-min-max-width')">
				Minimum and maximum width
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text fields have a minimum width of
					<strong>393px</strong> and a maximum width of
					<strong>620px</strong>, whenever possible.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Label</span>
					The purpose of the label is to tell users what information
					they need to enter. Labels should be short and use title
					case. Text field labels are typically descriptive nouns. If
					the label is a term that’s not widely recognized, or you
					think users might benefit from more information, you can use
					a <a href="components/help-text">help text</a> component to
					define the label or add additional context.
				</li>

				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Instructional text</span>
					Instructional text appears above the text field, below the
					label. Instructional text can be added to give users more
					direction or specific guidance on what type of information
					they should be entering. It should be short and verb-first.
					It’s not meant to replace the label or be used for examples.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="checkIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Enter a valid email address.
									</li>
									<li class="comparison-body-text-list-item">
										Choose a unique name you’ll remember.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="xMarkIcon"></kendo-svg-icon>
								Not This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Days Disconnected
									</li>
									<li class="comparison-body-text-list-item">
										e.g. Connector Name
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>

				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Placeholder text</span>
					Placeholder text goes inside the field and disappears when
					the user starts typing. Use placeholder text sparingly as
					it’s not usable or accessible. Placeholder text shouldn’t
					just repeat the information that’s in the label or
					instructional text. Use placeholder text to give an example
					of what the user should enter, or to ask a question or give
					a suggestion.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="checkIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										e.g., bob&#64;beyondtrust.com
									</li>
									<li class="comparison-body-text-list-item">
										What can we help you with?
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="xMarkIcon"></kendo-svg-icon>
								Not This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Enter your email address
									</li>
									<li class="comparison-body-text-list-item">
										Search
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>

				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Icon</span>
					An icon can be paired with a text field. This icon appears
					inside the text box and is left-aligned. The text input is
					indented enough to not cover the icon. Use icons with clear
					meanings and that are recognizable, like a magnifying glass
					for search. Text field icons are not interactable or in a
					changed state.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Error message</span>
					The success or error text that appears below the text field
					should be concise and helpful. It shouldn't exceed the width
					of the text field. Error messages can explain what happened,
					without using error code or jargon, and give users
					instructions on how they can resolve the error. If the field has instructional text, the error
					message appears below it.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="resources">
		<h2 id="sectionTitleResources " class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('resources')">
			Resources
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item resource-list-item">
				<a href="https://www.nngroup.com/articles/form-design-placeholders/" target="_blank">Placeholders in
					Form Fields are Harmful</a><span class="resource-source">NN/g Nielsen Norman Group</span>
			</li>
		</ul>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/button">Content guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/help-text">Help text component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/label-value">Label/Value pair component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
			<!-- <li class="page-related-list-item">
				<a href="components/number-field">Number Field Component</a>
			</li> -->
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
		</ul>
		<span class="page-related-list-title">Components and patterns that use text fields:</span>
		<ul class="page-section-list page-related-list">
			<!-- <li class="page-related-list-item">
				<a href="components/combo-box">Combo Box Component</a>
			</li> -->
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/date-picker">Date picker component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/filter">Filter pattern</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/time-picker">Time picker component</a>
			</li>
			<!-- <li class="page-related-list-item">
				<a href="patterns/form">Forms Pattern</a>
			</li> -->
			<!-- <li class="page-related-list-item">
				<a href="components/number-field">Number Field Component</a>
			</li> -->
			<!-- <li class="page-related-list-item">
				<a href="components/pagination">Pagination component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/password-field">Password Field Component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/range-slider">Range Slider Component</a>
			</li> -->
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Text Field Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>

<div id="search-container" class="search-container">	
	<div class="search-input-container">
		<kendo-autocomplete
			#SearchInput
			class="search-input" 
			[data]="seachResults"
			[filterable]="true"
			(filterChange)="handleFilter($event)"
			placeholder="Search Shield"
			valueField="route"
			(valueChange)="handleValueChange($event)"
			[suggest]="true"
			[popupSettings]="{ popupClass: 'kendo-search-container', appendTo:'component' }"
			[highlightFirst]="highlightFirst"
		>
			<!--<ng-template kendoAutoCompleteHeaderTemplate>
				<h3>Search Results:</h3>
			</ng-template>-->
		  
		  	<ng-template kendoPrefixTemplate>
				<kendo-svg-icon [icon]="searchIcon" class="shield-icon"></kendo-svg-icon>
			</ng-template>

			<ng-template kendoAutoCompleteItemTemplate let-dataItem>
				<div style="display: flex; flex-direction: column;">
					<span class="search-results-link">{{ dataItem.name }}</span>
					<span class="search-results-router">{{
						dataItem.route | formatCrumbs | titlecase
					}}</span>
				</div>
			</ng-template>

			<ng-template kendoAutoCompleteNoDataTemplate>
				No Results found
			</ng-template>
		</kendo-autocomplete>
	</div>
</div>

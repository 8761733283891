import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { searchData } from '../common/search/search-data';
import { ChangelogService } from 'src/app/services/changelog.service';

@Component({
	selector: 'app-general',
	templateUrl: './general.component.html',
	styleUrls: ['./general.component.less'],
})
export class GeneralComponent {
	public navData = searchData;

	constructor(
		private router: Router,
		public changelogService: ChangelogService
	) {}

	public filterItems(arr, section: string): any[] {
		return arr.filter((x) => x.route.indexOf(section + '/') !== -1);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}
}

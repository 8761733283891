import { Router, Event, NavigationEnd, PRIMARY_OUTLET, UrlTree, UrlSegmentGroup, UrlSegment } from '@angular/router';
import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { searchData } from './common/search/search-data';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent {
	@ViewChild('containerHost', { read: ViewContainerRef, static: false })
	containerHost: ViewContainerRef;

	public isAuthenticated: boolean = false;
	public loginCallback: boolean = false; // used for Okta authentication
	public navData = searchData;
	public latestVersion = environment.version;
	public navGuidelinesOpen = true;
	public navComponentsOpen = true;
	public navPatternsOpen = true;
	public navDevResourcesOpen = true;
	public currRoute = [];
	public currFullRoute = '';
	public currRouteArr = [];

	ngAfterViewInit() {}

	ngOnInit(): void {
		this._oktaStateService.authState$.subscribe(async (authState: AuthState) => {
			//even when logged in, the first authState obj returned always has isAuthenticated: false and no accessToken key
			//the following authState obj however contains an accessToken key and the correct value for isAuthenticated
			//if user is not logged in, redirect to Okta login, otherwise do nothing
			if ('accessToken' in authState && !authState.isAuthenticated) {
				await this._oktaAuth.signInWithRedirect({ originalUri: window.location.href });
			}
			this.isAuthenticated = authState.isAuthenticated ?? false;

			if (window.location.href.includes('/login/callback')) this.loginCallback = true;
			else this.loginCallback = false;
		});
	}

	constructor(
		private router: Router,
		private _oktaStateService: OktaAuthStateService,
		@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				const tree: UrlTree = router.parseUrl(event.urlAfterRedirects.toString());
				this.currRoute = [];
				this.currFullRoute = '';
				this.currRouteArr = [];
				const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
				if (g !== undefined) {
					const s: UrlSegment[] = g.segments ? g.segments : [];
					if (s.length > 1) {
						for (let i = 0; i < s.length; i++) {
							this.currRoute.push(s[i].path);
						}
					}
					this.currFullRoute = this.currRoute.join('/');
					this.currRouteArr = this.navData.filter(x => x.route === this.currFullRoute);
				}
			}
		});
	}

	public redirectTo(uri: any) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
	}

	public redirectToSection(section: string) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([section + '/overview']));
	}

	public filterSubNav(arr, section: string): any[] {
		return arr.filter(x => x.route.indexOf(section + '/') !== -1);
	}

	public getCurrPageName(): string {
		// const testName = data.filter((x) => x.route === route);
		// console.log(testName);
		// const currRoute = ''
		// let formattedRoute = '';
		// if (currRoute.length !== 0) {
		// 	for (let i = 0; i >= currRoute.length; i++) {
		// 		if (formattedRoute !== '') {
		// 			formattedRoute.concat('/');
		// 		}
		// 		formattedRoute.concat(currRoute[i].label);
		// 	}
		// }
		return '';
	}

	public toggleNavGuidelinesOpen(currValue: boolean): void {
		this.navGuidelinesOpen = !currValue;
	}

	public toggleNavComponentsOpen(currValue: boolean): void {
		this.navComponentsOpen = !currValue;
	}

	public toggleNavPatternsOpen(currValue: boolean): void {
		this.navPatternsOpen = !currValue;
	}

	public toggleNavDevResourcesOpen(currValue: boolean): void {
		this.navDevResourcesOpen = !currValue;
	}
}

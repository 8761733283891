<main class="page-main">
	<section class="page-section" id="sectionOverview">
		<h2 id="sectionTitleOverview" class="page-section-no-link-title">All patterns</h2>
		<ul class="overview-list">
			<li *ngFor=" let item of filterItems(navData, 'patterns')" class="overview-list-item">
				<div class="overview-list-item-container">
					<div class="overview-list-item-detail-image" (click)="redirectTo(item.route)">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path
								d="M448 0h-128c-17.67 0-32 14.33-32 32v24c0 17.67 14.33 32 32 32h16c8.836 0 16 7.162 16 16c0 8.836-7.164 16-16 16H320c-17.67 0-32 14.33-32 32V176c0 17.67 14.33 32 32 32h32C352 234.5 373.5 256 400 256C426.5 256 448 234.5 448 208h32c17.67 0 32-14.33 32-32V64C512 28.65 483.3 0 448 0zM480 176h-48C423.2 176 416 183.2 416 192v16C416 216.8 408.8 224 400 224S384 216.8 384 208V192c0-8.838-7.164-16-16-16H320V152h16c26.51 0 48-21.49 48-48s-21.49-48-48-48H320V32h128c17.67 0 32 14.33 32 32V176zM416 272h-64c-8.836 0-16 7.162-16 16v32c0 8.834-7.164 16-16 16s-16-7.166-16-16V288c0-8.838-7.164-16-16-16H240V96c0-17.67-14.33-32-32-32H64C28.65 64 0 92.65 0 128v160v160c0 35.35 28.65 64 64 64h160h160c35.35 0 64-28.65 64-64V304C448 286.3 433.7 272 416 272zM32 128c0-17.67 14.33-32 32-32h144v176h-32V256c0-26.51-21.49-48-48-48S80 229.5 80 256v16H32V128zM208 480H64c-17.67 0-32-14.33-32-32V304h64c8.836 0 16-7.164 16-16V256c0-8.838 7.164-16 16-16S144 247.2 144 256v32c0 8.836 7.164 16 16 16h48v32H192c-26.51 0-48 21.49-48 48c0 26.51 21.49 48 48 48h16V480zM416 448c0 17.67-14.33 32-32 32H240v-64c0-8.836-7.164-16-16-16H192c-8.836 0-16-7.166-16-16c0-8.836 7.164-16 16-16h32c8.836 0 16-7.166 16-16V304h32V320c0 26.51 21.49 48 48 48c26.51 0 48-21.49 48-48V304H416V448z" />
						</svg>
					</div>
					<div class="overview-list-item-detail-container" (click)="redirectTo(item.route)">
						<span class="overview-list-item-detail-title">
							{{item.name}}
						</span>
						<span class="overview-list-item-detail-sub-title">
							{{item.description}}
						</span>
					</div>
					<span class="overview-list-item-links" style="height:unset; padding-top:0px;">

					</span>
				</div>



			</li>
		</ul>
	</section>
</main>

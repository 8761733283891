import { Component, Input, OnInit } from '@angular/core';
import { searchData } from '../search/search-data';

@Component({
	selector: 'app-external-resource',
	templateUrl: './external-resource.component.html',
	styleUrls: ['./external-resource.component.less'],
})
export class ExternalResourceComponent implements OnInit {
	@Input() componentName: string;
	@Input() includeDescription: boolean = false;
	@Input() otherNames: boolean = false;

	public pageData = searchData;
	public currPageData = [];

	constructor() {}

	ngOnInit(): void {
		this.currPageData = this.pageData.filter(
			(x) =>
				x.name === this.componentName &&
				x.route.includes('guidelines') === false
		);
	}
}

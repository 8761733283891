<ng-container *ngIf="!isAuthenticated; else authenticatedView">
	<div class="app">
		<kendo-progressbar [indeterminate]="true"></kendo-progressbar>
		<h1 class="authenticating">Authenticating...</h1>
		<div class="shield-container">
			<router-outlet *ngIf="loginCallback"></router-outlet>
		</div>
	</div>
</ng-container>
<ng-template #authenticatedView>
	<div class="app">
		<header
			class="shield-header"
		>
			<span class="shield-header-info">
				<svg
					class="shield-header-menu-icon"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 448 512"
				>
					<path
						d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"
					/>
				</svg>
				<span
					class="shield-header-title"
					(click)="redirectTo('home')"
				>
					<img
						src="\assets\images\favicon.png"
						alt=""
						style="margin-right: 10px; width: 30px; height: 30px"
					/>
					Shield Design System
				</span>
				<span
					class="shield-page-current"
					*ngIf="currRouteArr.length > 0"
				>
					<span
						class="shield-page-current-section"
						*ngIf="currRoute.length > 1 && currRoute[0] !== 'general'"
						(click)="redirectToSection(currRoute[0])"
					>{{ currRoute[0]
						}}<svg
							class="shield-page-current-section-icon"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 320 512"
						>
							<path
								d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
							/>
						</svg></span>
					{{ currRouteArr[0].name }}
				</span>
			</span>
			<span class="shield-header-actions">
				<div class="shield-search">
					<app-search></app-search>
				</div>
				<!-- <span class="shield-feedback">
					<app-feedback></app-feedback>
				</span> -->
			</span>
		</header>
		<div class="shield-container">
			<section
				class="shield-sidebar"
			>
				<nav class="shield-navigation">
					<ul>
						<li class="nav-item nav-general">
							<ul>
								<li
									(click)="redirectTo('general/changelog')"
									class="nav-sub-item nav-sub-general"
								>
									Changelog
								</li>
								<li
									(click)="redirectTo('general/faq')"
									class="nav-sub-item nav-sub-general"
								>
									FAQ
								</li>
								<li
									class="nav-sub-item nav-sub-general"
									style="opacity:0.5; pointer-events: none;"
								>
									Glossary
								</li>
								<li
									class="nav-sub-item nav-sub-general"
									style="opacity:0.5; pointer-events: none;"
								>
									Contribute to Shield
								</li>
								<li
									class="nav-sub-item nav-sub-general"
									style="opacity:0.5; pointer-events: none;"
								>
									Contact Us
								</li>
							</ul>
						</li>
						<li class="nav-item">
							<span
								class="nav-item-container"
								(click)="toggleNavGuidelinesOpen(navGuidelinesOpen)"
							>Guidelines
								<svg
									class="nav-item-icon"
									aria-hidden="true"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path
										fill="currentColor"
										d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
									>
									</path>
								</svg>
							</span>
							<ul
								class="nav-sub-list"
								[class.nav-sub-list-guideline-open]="
									navGuidelinesOpen === true
								"
							>
								<li
									*ngFor="
										let subItem of filterSubNav(
											navData,
											'guidelines'
										)
									"
									class="nav-sub-item"
									(click)="redirectTo(subItem.route)"
									[class.nav-sub-list-selected]="currFullRoute === subItem.route"
								>
									{{ subItem.name }}
								</li>
							</ul>
						</li>
						<li class="nav-item">
							<span
								class="nav-item-container"
								(click)="toggleNavComponentsOpen(navComponentsOpen)"
							>Components
								<svg
									class="nav-item-icon"
									aria-hidden="true"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path
										fill="currentColor"
										d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
									>
									</path>
								</svg>
							</span>
							<ul
								class="nav-sub-list"
								[class.nav-sub-list-component-open]="
									navComponentsOpen === true
								"
							>
								<li
									*ngFor="
										let subItem of filterSubNav(
											navData,
											'components'
										)
									"
									class="nav-sub-item"
									[class.nav-sub-list-selected]="currFullRoute === subItem.route"
									(click)="redirectTo(subItem.route)"
								>
									{{ subItem.name }}
									<!--<a [href]="subItem.route">{{ subItem.name }}</a>-->
								</li>
							</ul>
						</li>
						<li class="nav-item">
							<span
								class="nav-item-container"
								(click)="toggleNavPatternsOpen(navPatternsOpen)"
							>Patterns
								<svg
									class="nav-item-icon"
									aria-hidden="true"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path
										fill="currentColor"
										d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
									>
									</path>
								</svg>
							</span>
							<ul
								class="nav-sub-list"
								[class.nav-sub-list-pattern-open]="
									navPatternsOpen === true
								"
							>
								<li
									*ngFor="
										let subItem of filterSubNav(
											navData,
											'patterns'
										)
									"
									class="nav-sub-item"
									(click)="redirectTo(subItem.route)"
									[class.nav-sub-list-selected]="
										currFullRoute === subItem.route
									"
								>
									{{ subItem.name }}
								</li>
							</ul>
						</li>
					</ul>
				</nav>
			</section>
			<router-outlet></router-outlet>
			<div
				id="dynamic-container-host"
				class="container-host"
			>
				<ng-container #containerHost></ng-container>
			</div>
		</div>
	</div>
	<app-clipboard></app-clipboard>
</ng-template>